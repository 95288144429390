import React from "react";

const Penne = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 300.000000 119.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)">
    <path d="M1116 2239 c-116 -12 -256 -60 -356 -121 -118 -72 -267 -227 -345
    -356 -5 -9 -13 -19 -16 -22 -12 -10 -108 -216 -125 -268 -11 -35 -13 -56 -7
    -65 6 -6 25 -57 43 -112 18 -55 40 -108 50 -119 17 -17 20 -18 61 -3 24 9 69
    23 99 31 30 9 96 27 145 41 50 15 95 28 100 29 6 2 17 6 25 9 8 3 40 13 70 21
    30 8 96 27 145 41 50 14 95 27 102 29 7 1 15 5 18 8 3 3 29 -1 58 -9 53 -14
    97 -23 132 -28 11 -2 39 -6 62 -9 185 -29 392 -36 633 -24 148 7 321 29 385
    48 17 5 52 12 79 16 26 3 92 20 145 36 53 16 101 30 106 31 6 1 16 6 23 10 6
    5 12 6 12 4 0 -3 24 7 53 22 54 27 65 47 53 96 -8 32 -25 36 -216 50 -47 3
    -107 7 -135 10 -27 2 -88 6 -135 9 -47 4 -105 8 -130 11 -73 8 -191 17 -249
    18 l-54 2 -34 100 c-18 55 -39 114 -47 130 -7 17 -19 48 -26 70 -27 91 -30 95
    -112 135 -43 22 -129 55 -191 74 -167 52 -288 68 -421 55z m331 -94 c126 -32
    240 -78 285 -115 25 -21 42 -47 54 -83 9 -28 34 -98 55 -155 43 -114 45 -122
    25 -122 -13 0 -99 -25 -571 -165 -99 -29 -268 -79 -375 -110 -197 -57 -230
    -67 -418 -125 -57 -17 -105 -30 -106 -28 -3 5 -38 121 -40 133 -1 5 -5 16 -9
    23 -16 24 7 106 57 205 169 336 406 529 696 567 97 13 237 3 347 -25z m483
    -490 c7 -9 11 -17 9 -19 -2 -2 -141 -42 -308 -90 -168 -48 -312 -91 -320 -96
    -31 -18 14 -11 96 14 43 14 103 31 133 39 30 8 100 29 155 45 165 50 253 72
    267 68 7 -3 60 -7 118 -11 58 -3 215 -15 350 -26 135 -11 277 -19 315 -17 64
    3 70 1 70 -17 0 -16 -7 -20 -36 -21 -19 -1 -64 -10 -100 -22 -384 -123 -909
    -168 -1278 -108 -52 9 -105 13 -119 9 -13 -3 -49 0 -79 8 -49 11 -53 14 -32
    22 25 9 328 100 556 166 72 21 142 46 154 55 29 20 33 20 49 1z"/>
    <path d="M875 2053 c-3 -21 -19 -81 -35 -133 -38 -128 -92 -342 -86 -347 5 -6
    155 39 276 82 52 19 141 48 198 66 56 17 102 35 102 39 0 13 -43 47 -329 260
    -52 39 -100 70 -106 70 -7 0 -16 -17 -20 -37z m210 -121 c50 -37 119 -89 154
    -116 l64 -49 -144 -49 c-213 -71 -371 -120 -375 -115 -5 5 32 163 66 282 11
    39 25 90 31 115 7 25 13 51 15 58 3 9 19 2 51 -23 27 -20 89 -67 138 -103z"/>
    <path d="M1003 1933 c-20 -7 -15 -29 17 -76 17 -25 30 -50 30 -57 0 -12 50 -6
    83 12 17 9 16 11 -4 39 -12 17 -32 34 -44 39 -13 5 -26 14 -30 19 -9 16 -38
    29 -52 24z m102 -83 c17 -18 16 -19 -10 -26 -21 -5 -31 -2 -41 11 -27 36 19
    50 51 15z"/>
    <path d="M910 1913 c0 -5 7 -30 15 -57 8 -27 15 -62 15 -78 0 -15 5 -28 10
    -28 21 0 80 29 80 39 0 14 -27 14 -35 1 -14 -22 -33 -8 -39 28 -10 51 -28 102
    -38 102 -4 0 -8 -3 -8 -7z"/>
    <path d="M898 1841 c-5 -18 -8 -49 -8 -67 0 -27 -4 -34 -20 -34 -24 0 -25 11
    -6 73 8 26 10 47 5 47 -9 0 -25 -52 -36 -114 l-6 -38 34 7 c19 4 38 10 42 15
    4 4 7 38 5 76 -3 58 -4 64 -10 35z"/>
    <path d="M1775 1835 c-5 -2 -86 -27 -180 -55 -93 -28 -181 -55 -195 -60 -14
    -5 -122 -38 -240 -74 -541 -165 -774 -237 -783 -243 -5 -3 -4 -8 2 -12 14 -9
    111 10 111 21 0 4 8 8 17 8 19 0 49 8 143 40 36 12 72 23 80 25 8 2 15 4 15 5
    0 1 8 3 18 6 31 7 41 10 117 33 41 12 107 33 145 45 39 13 111 36 160 51 50
    14 101 30 115 35 14 4 45 13 70 20 25 7 63 18 85 26 23 8 51 14 63 14 12 0 22
    4 22 8 0 6 90 38 97 34 2 0 18 5 38 14 19 8 35 11 35 7 0 -4 5 -2 12 5 7 7 20
    12 29 12 9 0 27 9 40 20 22 18 14 25 -16 15z"/>
    </g>
  </svg>
);

export default Penne;
