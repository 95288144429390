import React from "react";

const Toge = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 225.000000 225.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M596 2090 c-8 -3 -127 -151 -266 -330 -195 -253 -250 -331 -248 -350
    4 -31 240 -220 275 -220 17 0 40 23 91 93 38 51 80 109 93 130 29 44 40 47 34
    10 -3 -16 -17 -167 -30 -338 -14 -171 -37 -447 -51 -615 -24 -290 -25 -305 -8
    -317 14 -11 149 -13 673 -11 l655 3 11 22 c8 15 7 60 -3 160 -7 76 -34 359
    -61 628 -26 270 -47 491 -46 493 2 2 35 -43 75 -100 89 -126 109 -148 131
    -148 16 0 244 170 261 194 4 6 8 19 8 29 0 13 -413 591 -477 667 -6 6 -1099 7
    -1117 0z m263 -196 c-1 -85 2 -159 7 -164 5 -5 135 -10 289 -10 259 -1 280 0
    293 17 10 13 12 55 10 166 l-3 147 112 0 111 0 49 -67 c209 -286 324 -446 326
    -452 1 -5 -47 -45 -107 -89 l-109 -81 -71 104 c-39 57 -74 104 -77 105 -3 0
    -13 -6 -22 -12 -15 -12 -12 -55 38 -558 29 -300 56 -569 59 -597 l6 -53 -619
    0 -620 0 5 33 c7 52 83 1025 86 1107 3 69 1 75 -17 78 -15 2 -37 -22 -94 -103
    -41 -58 -76 -105 -78 -105 -4 0 -189 138 -216 161 -5 4 24 49 65 100 41 52
    131 169 201 262 l128 167 125 0 125 0 -2 -156z m571 13 c0 -98 -4 -147 -12
    -155 -17 -17 -509 -17 -526 0 -8 8 -12 57 -12 155 l0 142 275 0 275 0 0 -142z
    m-829 -419 c-4 -11 -10 -19 -14 -19 -4 1 -11 1 -17 1 -15 0 -12 21 7 42 20 22
    35 6 24 -24z m1105 7 c7 -29 1 -41 -15 -30 -14 8 -14 55 -1 55 5 0 12 -11 16
    -25z m-1484 -8 c34 -33 142 -113 177 -132 17 -8 31 -18 31 -21 0 -9 -76 -84
    -84 -84 -6 0 -177 131 -210 161 -7 6 50 99 60 99 2 0 13 -10 26 -23z m1876 -4
    c2 -12 10 -27 18 -33 8 -7 14 -18 14 -25 0 -7 -48 -50 -106 -95 l-106 -83 -24
    29 c-13 16 -23 34 -24 41 0 6 -5 13 -11 15 -6 2 34 37 88 77 54 41 102 81 106
    88 12 21 41 11 45 -14z m-1303 -1163 l240 -6 -257 -2 c-225 -2 -257 0 -252 13
    4 8 11 12 18 7 6 -4 119 -9 251 -12z m985 0 c0 -6 -83 -9 -232 -8 l-233 2 220
    6 c121 3 226 7 233 8 6 1 12 -2 12 -8z m0 -59 c0 -22 3 -46 6 -55 6 -15 -54
    -16 -630 -16 l-636 0 0 24 c0 13 3 38 6 55 l7 31 623 0 624 0 0 -39z"/>
    <path d="M711 1162 c-13 -4 -33 -19 -44 -33 -18 -25 -18 -28 -3 -57 10 -19 17
    -64 19 -124 4 -134 38 -183 130 -192 52 -5 99 9 127 39 30 31 32 31 67 -4 l30
    -29 109 4 c132 5 137 5 244 -1 96 -5 152 10 189 49 30 32 38 95 17 136 -11 23
    -13 37 -6 50 6 10 10 36 10 57 0 51 -44 99 -98 107 -115 16 -206 4 -228 -30
    -15 -22 -14 -27 5 -67 21 -43 27 -100 15 -132 -5 -13 -9 -14 -15 -4 -10 17
    -21 -44 -16 -93 l3 -38 -123 0 -123 0 0 25 c0 18 5 25 20 25 19 0 20 7 20 119
    0 116 -1 119 -21 114 -18 -5 -20 -2 -17 22 3 27 4 27 86 28 l82 2 0 -29 c0
    -25 -3 -28 -25 -24 l-25 5 0 -118 c0 -113 1 -119 20 -119 12 0 38 19 60 43 41
    43 38 61 -5 33 -13 -9 -27 -16 -30 -16 -3 0 -5 29 -5 64 0 48 5 69 20 89 25
    32 25 59 1 81 -21 19 -72 22 -271 17 -58 -1 -125 0 -150 3 -25 3 -56 2 -69 -2z
    m787 -31 c31 -1 72 -44 72 -75 0 -13 -11 -38 -25 -54 -24 -29 -24 -31 -7 -36
    19 -6 42 -45 42 -71 0 -28 -25 -67 -51 -81 -19 -9 -61 -14 -129 -14 l-100 0 0
    25 c0 17 5 25 18 25 15 0 17 12 16 117 -1 114 -2 118 -23 115 -17 -3 -21 2
    -21 22 0 24 3 25 68 28 37 2 78 2 92 1 14 -1 35 -2 48 -2z m-668 -26 c0 -16
    -6 -25 -15 -25 -12 0 -15 -18 -15 -104 0 -96 2 -106 22 -120 16 -12 26 -13 42
    -4 33 17 36 27 36 131 0 80 -3 97 -15 97 -9 0 -15 9 -15 25 0 24 3 25 60 25
    57 0 60 -1 60 -25 0 -18 -5 -25 -19 -25 -18 0 -20 -10 -23 -120 -3 -120 -3
    -120 -33 -145 -42 -34 -122 -35 -162 -1 -27 24 -28 27 -31 145 -3 111 -5 121
    -23 121 -22 0 -27 35 -6 43 6 3 40 6 75 6 60 1 62 0 62 -24z m30 -130 c0 -43
    -4 -75 -10 -75 -6 0 -10 32 -10 75 0 43 4 75 10 75 6 0 10 -32 10 -75z m160
    -7 c-1 -78 -16 -129 -33 -111 -18 18 0 193 19 193 11 0 14 -19 14 -82z"/>
    <path d="M1423 1083 c-8 -2 -13 -22 -13 -44 0 -37 2 -39 30 -39 50 0 64 57 19
    80 -10 6 -19 10 -21 9 -2 0 -9 -3 -15 -6z"/>
    <path d="M1410 900 c0 -49 1 -50 29 -50 33 0 61 23 61 49 0 25 -32 51 -63 51
    -26 0 -27 -2 -27 -50z"/>
    <path d="M1053 1763 c65 -2 168 -2 230 0 62 1 9 3 -118 3 -126 0 -177 -2 -112
    -3z"/>
    <path d="M230 1448 c34 -29 136 -105 150 -112 20 -10 4 4 -75 65 -59 44 -109
    77 -75 47z"/>
    <path d="M1961 1400 c-44 -33 -81 -63 -81 -66 0 -3 35 21 78 52 84 62 99 74
    90 74 -3 0 -42 -27 -87 -60z"/>
    </g>
    </svg>
);

export default Toge;