import React from "react";

const Question = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="-39.34 0 230.34 230.34"
    preserveAspectRatio="xMidYMid meet"
  >
    <path d="M92.17,118.09c-.87,3-2,5.42-2.19,7.88-.73,9.66-2.85,19.27-1,29.06.62,3.32-.43,4.7-3.49,6a19.65,19.65,0,0,1-4.08,1.26,62.72,62.72,0,0,1-28.93-.9,46.2,46.2,0,0,1-7.8-3.54c-3.16-1.56-4.35-4.18-4.37-7.7,0-13.46-.53-26.91-.26-40.36.13-6.54,1.4-13.07,2.45-19.56A31.16,31.16,0,0,1,48,77.63c4.15-1.38,8.45-.92,12.7-.86a83.38,83.38,0,0,0,23.79-3c3.65-1,6.89-2.76,9-6.16a9.27,9.27,0,0,0,.49-9.74C89.2,48.57,82,44.93,72,47.79c-14.47,4.13-25.56,12.5-31.67,26.72-2.92,6.8-5,7.77-11.79,5.93a16.71,16.71,0,0,1-4.79-1.84C16.57,73.9,9.43,69.14,2.39,64.23.1,62.64-.4,60.33.28,57.41a34.36,34.36,0,0,1,6.2-13.08A152.75,152.75,0,0,1,41.1,11.24C48.57,6,57.21,4,65.89,2.08,76.63-.3,87.27-.87,98.22,1.6A52.15,52.15,0,0,1,126,17.54c4.64,5.06,9,10.39,13.23,15.81a59.23,59.23,0,0,1,11.09,24.82c4.65,22.12-3.36,39.08-20.11,53-6.1,5.06-13.15,6.7-20.74,6.89C104.06,118.2,98.63,118.09,92.17,118.09Zm-12.63,35.2c.24-1.8.58-3.73.74-5.68.5-6,.79-12,1.45-18,.56-5.12,1.56-10.19,2.28-15.29.43-3,2.27-4.54,5-5a25.09,25.09,0,0,1,5.11-.44c5.16.12,10.31.29,15.45.6a19.35,19.35,0,0,0,10-1.67c10.44-5.26,18.35-13,21.34-24.48,2.56-9.8,2.67-19.8-1.26-29.54a59.65,59.65,0,0,0-8.88-15.6c-3.81-4.66-7.39-9.52-11.42-14-6.26-6.9-13.68-12.07-23-14.34C79.58,5.71,63.56,8.48,48.09,15.8a31,31,0,0,0-4.32,2.8C32.19,26.74,22.38,36.75,13.49,47.66c-2.29,2.81-4.82,5.72-4.93,9.64A68.21,68.21,0,0,0,32.27,73.21l4.27-7.15c3.4-5.68,7-11.24,12.52-15.09,8.08-5.64,17-9.86,26.67-11.32,13.48-2,23.84,4.81,27.91,19.59a14.25,14.25,0,0,1-.76,9.28C100.34,74.66,96.25,79,89.71,81.09a68.91,68.91,0,0,1-21.95,3.45c-4.51,0-9.07-.48-13.29.43-3.58,5-4,10.56-4.28,16-.72,13.39-1.19,26.79-1.67,40.19A56.2,56.2,0,0,0,49,147.5C59.45,154.9,67.7,156.45,79.54,153.29Z"/>

    <path d="M65,230.31a27.48,27.48,0,0,1-26.76-17.82,20.66,20.66,0,0,1-1.56-10.07c1.21-9.33,4.72-17.43,11.77-24.13,6.5-6.19,13.47-7.72,22-5.49,5.61,1.47,11.06,2.83,15.42,6.95,6.45,6.08,9.93,13.2,8.3,22.27-.25,1.41-.38,2.84-.62,4.25C90.9,221.87,80.84,230.33,65,230.31ZM66.79,222a38.22,38.22,0,0,0,5.94-1.33c7.75-2.77,14.61-13.85,13.67-21.93a16.51,16.51,0,0,0-1.31-4c-4-10.17-12.6-13.4-22.46-14.34A9,9,0,0,0,57,181.82c-6,4.3-10.38,10.17-11.58,17.39C43.44,211.17,53.14,222.26,66.79,222Z"/>

    </svg>
);

export default Question;