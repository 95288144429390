import React from "react";

const ULB = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 300.000000 119.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M1284 2757 c-116 -194 -357 -593 -534 -887 -728 -1206 -739 -1226
    -733 -1248 6 -25 20 -29 25 -7 3 14 166 15 1458 15 1292 0 1455 -1 1458 -15 5
    -22 19 -18 26 7 4 17 -11 51 -58 128 -34 58 -354 589 -711 1180 -356 591 -662
    1098 -679 1127 -17 29 -33 52 -36 52 -3 0 -100 -158 -216 -352z m368 51 c80
    -134 401 -666 713 -1183 312 -517 570 -946 572 -952 4 -10 -287 -13 -1436 -13
    -793 0 -1441 2 -1441 5 0 3 53 94 119 203 241 400 791 1314 921 1527 73 121
    192 318 263 438 72 119 134 217 137 217 4 0 72 -109 152 -242z"/>
    <path d="M1280 1597 l0 -805 23 -6 c12 -3 151 -6 310 -6 280 0 287 0 287 20 0
    20 -7 20 -290 20 l-290 0 -2 787 c-3 743 -4 788 -21 791 -16 3 -17 -38 -17
    -801z"/>
    <path d="M660 1533 c-146 -296 -288 -585 -316 -643 -41 -83 -49 -107 -38 -113
    8 -4 14 -15 14 -24 0 -14 38 -15 400 -11 336 5 401 8 414 21 13 12 16 83 21
    458 3 244 8 519 10 612 6 162 5 167 -14 167 -18 0 -20 -8 -23 -122 -2 -68 -5
    -283 -6 -478 -1 -195 -5 -415 -9 -488 l-6 -132 -388 0 -388 0 246 498 c374
    757 383 776 370 784 -7 4 -15 8 -18 8 -3 0 -124 -242 -269 -537z"/>
    <path d="M1696 1979 c-4 -16 47 -218 151 -601 132 -484 161 -578 176 -578 9 0
    17 6 17 13 0 6 -33 130 -74 275 -41 149 -68 261 -61 258 16 -6 71 39 106 87
    16 22 29 33 29 25 0 -10 26 -22 82 -37 272 -73 414 -247 330 -407 -50 -96
    -189 -183 -354 -222 -53 -13 -68 -20 -68 -34 0 -22 4 -22 88 -2 166 40 329
    148 373 250 28 62 27 151 -2 210 -30 62 -107 135 -187 177 -72 37 -189 77
    -228 77 -28 0 -29 6 -8 71 24 72 15 181 -20 252 -49 100 -175 188 -292 203
    -48 6 -52 5 -58 -17z m161 -55 c158 -76 230 -256 161 -404 -24 -53 -101 -140
    -122 -140 -8 0 -126 408 -164 568 -5 20 57 8 125 -24z"/>
    </g>
    </svg>
);

export default ULB;
