import React from "react";

const Team = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 786.5 786.5"
    preserveAspectRatio="xMidYMid meet"
  >
    <g>
        <g>
            <path d="M566,549.2L566,549.2L566,549.2c-0.199-1.6-0.6-3.201-1.1-4.701c-3.801-14.6-12.5-41.299-16.5-53.199
                c-0.4-2.1-1.1-4.1-2-6c0,0-6.4-9.6-11.201-12.5c0,0-2.398-1.301-2.699-1.4c-9.5-4.5-38.9-16.299-38.9-16.299l0,0
                c-14.699-6.4-29.9-11.801-45.4-17.301l0,0l0,0l0,0l0,0c-2.799,11.5-29.1,88.1-33.299,98.801l-3.301-71.201
                c1.1-1.699,1.9-3.6,2.801-5.4l11-21.699c-7.701,6.4-19.301,10.5-32.3,10.5c-12.7,0-24-3.9-31.7-10.1l10.7,21.199
                c0.9,1.801,1.7,3.801,2.8,5.4l-3.3,71.199c-4.2-10.6-30.5-87.199-33.3-98.799l0,0l0,0l0,0l0,0c-15.5,5.5-30.7,11-45.4,17.299l0,0
                c0,0-29.4,11.801-38.9,16.301c-0.3,0.199-2.4,1.199-2.7,1.4c0,0-8.8,7.299-11.2,12.4c0,0-1.6,3.898-2,6
                c-3.9,11.898-12.6,38.5-16.5,53.199c-0.5,1.5-0.9,3.1-1.1,4.699l0,0l0,0c-0.2,1.4-0.3,2.9-0.3,4.301c0,15.699,12.7,28.4,28.4,28.4
                h27.1c38.8,0,77.5,0,116.3,0c0.5,0,1,0,1.5,0l0,0c0.5,0,1,0,1.5,0c38.801,0,77.5,0,116.301,0h27.1c15.699,0,28.4-12.701,28.4-28.4
                C566.301,552.101,566.199,550.601,566,549.2z"/>
            <path d="M467.199,306.2c0-5,1-23.2,1-27.3c0-40.5-32.898-73.4-73.399-73.4l0,0l0,0H394.7h-0.1l0,0l0,0c-0.5,0-0.9,0-1.4,0
                s-0.899,0-1.399,0l0,0l0,0H391.7h-0.1l0,0l0,0c-40.5,0-73.4,32.9-73.4,73.4c0,4.2,1,22.3,1,27.3c-1.8,0.1-18.199-3.9-16.399,15.8
                c3.7,41.7,19.7,33.7,20.1,33.7c7.8,25.2,20,41.2,31.9,51.401c18.6,15.898,36.6,17.699,37,17.699c0.5,0,1,0,1.5,0l0,0l0,0
                c0.5,0,1,0,1.5,0c0.299,0,18.2-1.699,36.7-17.5c12-10.199,24.199-26.3,32.1-51.6c0.5,0,16.4,8.1,20.1-33.7
                C485.4,302.3,469,306.3,467.199,306.2z"/>
            <path d="M218.3,476.8l0.2-0.6l0.3-0.6c2.2-4.801,5.8-9.5,11.3-14.9c1.2-1.1,4.8-4.701,7.7-6.701l0,0c0.2-0.1,0.4-0.299,0.6-0.398
                c0.1-0.102,0.2-0.201,0.4-0.201l0,0c2-1.299,4.4-2.4,5.2-2.799c6.5-3.102,20.5-8.801,30.2-12.801c-0.3-0.1-0.6-0.301-1-0.5
                c-0.3-0.1-0.6-0.301-0.9-0.4c-8.3-3.9-33.9-14.199-33.9-14.199l0,0c-12.8-5.5-26.1-10.301-39.6-15.1l0,0
                c-2.4,10.1-25.4,76.799-29,86.1l-2.9-62.1c1-1.5,1.6-3.102,2.4-4.701l9.6-18.9c-6.7,5.602-16.8,9.201-28.1,9.201
                c-11,0-20.9-3.4-27.7-8.801l9.4,18.5c0.8,1.6,1.4,3.301,2.4,4.701l-2.9,62.1c-3.6-9.201-26.6-76-29-86.1l0,0
                c-13.5,4.799-26.8,9.5-39.6,15.1l0,0c0,0-25.6,10.299-33.9,14.199c-0.3,0.1-0.6,0.301-0.9,0.4c-0.7,0.4-1.2,0.6-1.5,0.801l0,0
                c-4.3,2.5-7.7,6.299-9.8,10.799l0,0c0,0,0,0,0,0.1c-0.8,1.602-1.4,3.4-1.7,5.201c-3.4,10.4-11,33.6-14.3,46.299
                c-0.4,1.301-0.8,2.701-1,4.102l0,0l0,0c-0.2,1.199-0.3,2.5-0.3,3.799c0,13.701,11.1,24.701,24.7,24.701h23.6
                c33.8,0,67.6,0,101.4,0c0.4,0,0.9,0,1.3,0s0.9,0,1.3,0c16.1,0,32.2,0,48.3,0c4.2-14.801,10.9-35.602,15.1-48.102
                C216.3,481.899,217.5,478.601,218.3,476.8z"/>
            <path d="M89.4,335.8c6.8,21.9,17.4,35.9,27.8,44.8c16.2,13.899,31.9,15.399,32.2,15.399c0.4,0,0.9,0,1.3,0s0.9,0,1.3,0
                c0.3,0,15.9-1.5,32-15.299c10.5-8.9,21.1-22.9,28-45c0.4,0,14.3,7.1,17.6-29.3c1.5-17.2-12.8-13.7-14.3-13.8
                c0-4.4,0.9-20.2,0.9-23.8c0-35.3-28.7-64-64-64l0,0h-0.1H152l0,0l0,0c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0l0,0l0,0h-0.1h-0.1
                l0,0l0,0c-35.3,0-64,28.7-64,64c0,3.6,0.9,19.5,0.9,23.8c-1.6,0.1-15.9-3.4-14.3,13.8C75.1,342.9,89,335.8,89.4,335.8z"/>
            <path d="M786,483.399c-0.199-2.299-0.5-4.6-0.801-6.9c0,0-1.299-7.398-1.6-8.6c-3-13.1-11.1-22.6-22.1-30.1
                c-2.1-1.4-4.199-2.6-6.301-3.699c-2.799-1.5-45.799-15.602-46.898-16c0.299,1.5,0.398,3.1,0.5,4.898
                c6,4.201,23.6,19.4,15.6,47.701c0,0-12.701-15.1-25.6-17c-12.301,21.4-33.201,48.6-44.5,55.699
                C643,502.3,622.1,475.2,609.801,453.7c-12.9,1.9-25.602,17-25.602,17c-8-28.4,9.602-43.5,15.602-47.701
                c0.1-1.799,0.199-3.398,0.5-4.898c-1.102,0.398-44.102,14.5-46.9,16c-2.201,1.1-4.301,2.299-6.301,3.699
                c-4.299,2.9-8.1,6.1-11.4,9.699c2.602,1.102,4.801,2.102,6.602,2.9c0.699,0.301,2.799,1.301,4.699,2.5l0,0
                c4.5,2.701,8.9,6.801,13.699,12.801c4.801,6.1,6.4,9,7.201,10.9c1.199,2.699,2.199,5.6,2.9,8.5c4.1,12.5,10.898,33.299,15,48
                c22.799,0,45.699,0,68.5,0c33.1,0,66.199,0,99.299,0c9.9,0,18.301-3,25.301-10.4c5.4-5.801,7-12.701,7.4-20.201
                c0.1-1.299,0.199-7,0.199-7S786,483.499,786,483.399z"/>
            <path d="M576.4,348.4c1,3.7,1.699,7.2,1.699,10.5c0,1.1-0.1,2.2-0.299,3.3c-0.9,3.1-7.5,13.7-8.602,15.5
                c11.701,12.6,27.102,15.5,42.301,18.7c4.5,0.9,9.1,1.5,14,2.1c1.6,0.301,3.199,0.602,4.801,0.9l-7.701,22.6
                c0,0-0.199,33,31.701,53.102h0.199c31.9-20.102,31.699-53.102,31.699-53.102l-7.699-22.6c1.6-0.299,3.199-0.6,4.801-0.9
                c4.898-0.6,9.5-1.199,14-2.1C712.5,393.2,728,390.3,739.6,377.7c-1.1-1.8-7.699-12.4-8.6-15.5c-0.199-1.1-0.301-2.2-0.301-3.3
                c0-3.3,0.602-6.8,1.701-10.5c1-3.5,2.1-7,3.199-10.6c5.201-11.5,8-24.2,8-37.7c0-49.9-39.699-90.5-89-91.4l0,0h-0.1h-0.1l0,0
                c-49.301,0.9-89,41.5-89,91.4c0,13.4,2.9,26.2,8,37.7C574.301,341.4,575.4,344.9,576.4,348.4z"/>
        </g>
    </g>
    </svg>
);

export default Team;