import React from "react";

const Website = ({ color, size }) => (
    <svg 
    version="1.0" 
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={size}
    height={size}
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M400 949 c-135 -26 -269 -142 -330 -285 l-19 -44 23 0 c18 0 27 9 40
    43 9 23 25 53 35 66 l18 23 60 -22 61 -22 -9 -31 c-13 -46 -11 -57 10 -57 15
    0 21 10 26 41 6 36 10 40 29 35 12 -3 48 -8 79 -12 55 -6 57 -8 57 -35 0 -22
    5 -29 20 -29 15 0 20 7 20 29 0 27 2 29 57 35 31 4 67 9 79 12 19 5 23 1 29
    -35 5 -31 11 -41 26 -41 21 0 23 11 10 57 l-9 31 61 22 60 22 18 -23 c10 -13
    26 -43 35 -66 12 -31 22 -43 36 -43 17 0 19 4 13 28 -22 88 -136 217 -235 265
    -90 44 -195 56 -300 36z m76 -226 c-2 -3 -34 -2 -71 2 -66 7 -66 8 -60 34 10
    42 66 122 99 142 l31 19 3 -96 c1 -53 1 -99 -2 -101z m139 114 c18 -29 37 -64
    40 -78 6 -25 4 -26 -47 -32 -95 -12 -88 -20 -88 93 l0 101 30 -16 c17 -9 46
    -39 65 -68z m-264 22 c-17 -22 -35 -56 -41 -75 -6 -19 -16 -34 -21 -34 -16 0
    -89 32 -89 40 0 12 31 36 94 74 79 47 93 46 57 -5z m327 22 c50 -26 122 -79
    122 -90 0 -8 -71 -41 -89 -41 -5 0 -15 15 -21 34 -6 19 -24 53 -41 75 -32 45
    -26 50 29 22z"/>
    <path d="M62 568 c3 -7 15 -43 27 -80 20 -59 26 -68 46 -68 20 0 27 9 41 55
    10 30 21 55 24 55 3 0 14 -25 24 -55 14 -47 21 -55 41 -55 21 0 27 9 45 68 12
    37 24 73 26 80 4 8 -3 12 -20 12 -18 0 -26 -6 -27 -17 0 -10 -6 -36 -13 -58
    l-13 -40 -18 58 c-16 50 -22 57 -45 57 -23 0 -29 -7 -45 -57 l-18 -58 -13 40
    c-7 22 -13 48 -13 58 -1 11 -9 17 -27 17 -17 0 -24 -5 -22 -12z"/>
    <path d="M362 568 c3 -7 15 -43 27 -80 20 -59 26 -68 46 -68 20 0 27 9 41 55
    10 30 21 55 24 55 3 0 14 -25 24 -55 14 -46 21 -55 41 -55 20 0 26 9 46 68 12
    37 24 73 27 80 2 7 -5 12 -22 12 -18 0 -26 -6 -27 -17 0 -10 -6 -36 -13 -58
    l-13 -40 -18 58 c-16 50 -22 57 -45 57 -23 0 -29 -7 -45 -57 l-18 -58 -13 40
    c-7 22 -13 48 -13 58 -1 11 -9 17 -27 17 -17 0 -24 -5 -22 -12z"/>
    <path d="M662 568 c3 -7 15 -43 27 -80 20 -59 26 -68 46 -68 20 0 27 9 41 55
    10 30 21 55 24 55 3 0 14 -25 24 -55 14 -46 21 -55 41 -55 20 0 26 9 46 68 12
    37 24 73 27 80 2 7 -5 12 -22 12 -18 0 -26 -6 -27 -17 0 -10 -6 -36 -13 -58
    l-13 -40 -18 58 c-16 50 -22 57 -45 57 -23 0 -29 -7 -45 -57 l-18 -58 -13 40
    c-7 22 -13 48 -13 58 -1 11 -9 17 -27 17 -17 0 -24 -5 -22 -12z"/>
    <path d="M70 336 c87 -203 290 -321 498 -289 159 25 291 129 360 286 l21 47
    -23 0 c-18 0 -27 -10 -40 -42 -9 -24 -25 -54 -35 -67 l-18 -23 -60 22 -61 22
    9 31 c13 46 11 57 -10 57 -15 0 -21 -10 -26 -41 -6 -36 -10 -40 -29 -35 -12 3
    -48 8 -79 12 -55 6 -57 8 -57 35 0 22 -5 29 -20 29 -15 0 -20 -7 -20 -29 0
    -27 -2 -29 -57 -35 -31 -4 -67 -9 -79 -12 -19 -5 -23 -1 -29 35 -5 31 -11 41
    -26 41 -21 0 -23 -11 -10 -57 l9 -31 -61 -22 -60 -22 -18 23 c-10 13 -26 43
    -35 67 -13 33 -22 42 -40 42 l-23 0 19 -44z m410 -156 l0 -101 -30 16 c-33 17
    -95 104 -105 147 -7 29 -1 31 103 36 l32 2 0 -100z m128 93 c51 -6 53 -7 47
    -32 -10 -43 -73 -129 -105 -146 l-30 -16 0 101 c0 113 -7 105 88 93z m-298
    -57 c6 -19 24 -53 41 -75 16 -23 24 -41 19 -41 -28 0 -170 91 -170 109 0 9 65
    40 87 40 6 1 17 -14 23 -33z m450 18 c22 -9 40 -20 40 -25 0 -18 -142 -109
    -170 -109 -5 0 3 18 19 41 17 22 35 56 41 75 13 39 16 40 70 18z"/>
    </g>
    </svg>
);

export default Website;
