import React from "react";

const Loudspeaker = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
  >
    <g>
        <path class="st0" d="M98.484,299.703V150.469H42.297C18.938,150.469,0,169.406,0,192.766v64.625
            c0,23.359,18.938,42.313,42.297,42.313H98.484z"/>
        <path class="st0" d="M317.359,82.359l-99.297,53.109c-18.422,9.844-38.984,15-59.859,15h-33.844v149.234h33.844
            c20.875,0,41.438,5.141,59.859,15l99.297,53.109v12.969h25.859V69.391h-25.859V82.359z M317.641,338.953l-87.516-46.813
            c-22.047-11.797-46.922-18.031-71.922-18.031h-8.266v-98.063h8.266c25,0,49.875-6.234,71.922-18.031l87.516-46.797V338.953z"/>
        <rect x="352.172" y="195.234" class="st0" width="20.891" height="59.703"/>
        <path class="st0" d="M13.734,407.094v16.578c0,10.469,8.484,18.938,18.953,18.938h31.594c10.453,0,18.938-8.469,18.938-18.938
            v-10.391l32.422-89.563H39.984L13.734,407.094z"/>
        <rect x="423.094" y="217.172" class="st0" width="88.906" height="15.984"/>
        
            <rect x="440.238" y="106.255" transform="matrix(0.509 0.8608 -0.8608 0.509 346.5685 -313.6643)" class="st0" width="15.993" height="81.413"/>
        <polygon class="st0" points="409.125,285.625 479.203,327.063 487.344,313.313 417.266,271.859 	"/>
    </g>
  </svg>
);

export default Loudspeaker;