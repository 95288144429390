import React from "react";

const Solidarity = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 300.000000 119.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,220.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M1315 2647 c-55 -22 -84 -48 -110 -100 -29 -59 -32 -108 -9 -163 19
    -45 279 -314 304 -314 9 0 79 64 156 143 144 146 164 177 164 254 0 40 -44
    125 -77 149 -62 45 -146 56 -207 28 -32 -16 -37 -16 -75 0 -48 18 -103 20
    -146 3z m151 -92 l33 -25 37 25 c27 18 50 25 87 25 44 0 53 -4 83 -36 29 -31
    34 -42 34 -86 l0 -50 -117 -122 c-65 -66 -120 -121 -123 -121 -3 0 -58 55
    -122 121 l-118 122 0 52 c0 46 4 56 34 86 30 30 40 34 87 34 40 0 60 -6 85
    -25z"/>
    <path d="M829 2121 c-111 -70 -109 -234 3 -308 53 -35 135 -38 188 -7 121 70
    124 241 7 317 -59 39 -136 38 -198 -2z m151 -56 c51 -26 75 -98 49 -148 -20
    -39 -51 -57 -99 -57 -88 0 -136 81 -95 160 28 54 90 73 145 45z"/>
    <path d="M1985 2133 c-63 -34 -98 -95 -97 -171 1 -162 197 -239 313 -123 87
    86 69 225 -36 286 -50 30 -133 33 -180 8z m168 -93 c64 -72 15 -180 -83 -180
    -88 0 -136 81 -95 160 35 67 127 77 178 20z"/>
    <path d="M1235 2101 c-49 -20 -66 -67 -90 -242 -4 -33 -14 -54 -36 -75 l-30
    -29 -177 -5 c-163 -5 -180 -7 -215 -29 -47 -29 -82 -75 -96 -126 -17 -61 -14
    -328 3 -370 18 -43 67 -69 113 -62 l35 6 -4 -152 c-3 -170 2 -188 65 -228 41
    -26 113 -21 153 10 l27 21 24 -21 c16 -16 39 -24 75 -26 65 -5 113 17 145 66
    23 34 23 38 23 361 0 314 1 328 20 353 43 54 70 138 96 297 24 138 25 163 14
    191 -23 54 -90 82 -145 60z m64 -85 c9 -11 7 -46 -9 -147 -26 -172 -40 -215
    -101 -301 -18 -24 -19 -49 -19 -359 0 -377 2 -369 -75 -369 -71 0 -80 19 -77
    160 2 74 -1 127 -8 139 -13 25 -55 28 -64 5 -3 -9 -6 -72 -6 -140 0 -111 -2
    -126 -20 -144 -28 -28 -84 -27 -104 2 -14 19 -16 67 -16 325 0 289 -1 303 -19
    313 -25 13 -27 13 -45 -6 -13 -12 -16 -40 -16 -134 0 -111 -1 -119 -20 -125
    -44 -14 -50 6 -50 179 0 142 2 165 20 193 32 53 49 58 242 63 l176 5 38 31
    c63 50 75 74 91 180 9 54 19 109 24 122 10 25 41 29 58 8z"/>
    <path d="M1683 2100 c-29 -12 -63 -67 -63 -101 0 -49 41 -273 61 -336 11 -34
    33 -80 50 -103 l29 -40 0 -326 c-1 -316 0 -326 20 -360 12 -19 36 -42 53 -51
    44 -23 124 -18 161 9 23 17 32 19 45 9 63 -46 119 -49 177 -9 55 38 64 71 64
    229 l0 141 25 -6 c33 -8 87 15 108 47 14 22 17 53 17 203 0 201 -10 238 -76
    293 -52 42 -96 51 -257 51 -89 0 -156 5 -171 12 -42 19 -59 57 -77 177 -14 94
    -22 118 -42 138 -27 27 -88 39 -124 23z m66 -73 c6 -8 16 -49 21 -93 14 -100
    26 -141 57 -182 45 -59 70 -66 260 -72 153 -4 175 -7 200 -26 54 -40 63 -73
    63 -244 0 -155 -5 -180 -36 -180 -7 0 -20 7 -28 16 -13 12 -16 40 -16 134 0
    111 -1 119 -20 125 -48 15 -50 3 -50 -306 0 -158 -4 -299 -9 -312 -5 -13 -23
    -30 -41 -37 -28 -12 -34 -11 -58 7 -27 20 -27 23 -32 158 -4 117 -8 140 -22
    149 -13 8 -23 8 -35 0 -16 -10 -18 -30 -21 -147 -2 -133 -3 -136 -29 -156 -32
    -26 -74 -27 -104 -2 l-24 19 -3 341 -3 340 -34 50 c-41 62 -58 121 -80 271 -9
    63 -19 118 -21 122 -6 9 25 38 40 38 8 0 19 -6 25 -13z m520 -811 c13 -16 6
    -42 -15 -50 -10 -4 -14 3 -14 29 0 37 10 44 29 21z"/>
    </g>
    </svg>
);

export default Solidarity;