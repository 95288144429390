import React from "react";

const Teddy = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 243 300"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M1100 2689 c-19 -4 -53 -11 -75 -14 -109 -16 -254 -134 -295 -240
    -35 -88 -41 -345 -8 -345 6 0 8 11 5 30 -5 22 -2 32 12 39 10 6 20 9 22 8 2
    -1 -3 4 -11 11 -9 8 -11 15 -5 20 14 10 17 32 4 32 -6 0 -8 9 -4 21 5 15 4 19
    -4 15 -7 -5 -11 2 -11 16 0 18 2 20 10 8 9 -13 10 -13 10 0 0 8 -4 21 -9 29
    -5 8 -3 23 4 38 8 14 11 32 8 39 -3 8 -1 14 5 14 8 0 23 34 21 48 0 2 7 9 17
    16 9 7 14 18 10 24 -5 8 0 12 12 12 11 0 22 7 26 15 3 8 15 15 26 15 11 0 20
    5 20 10 0 6 -6 10 -12 10 -31 0 36 31 79 37 26 3 51 11 55 17 5 8 8 7 8 -1 0
    -13 59 -12 91 1 8 4 32 0 52 -9 47 -20 68 -19 60 1 -3 10 -9 12 -15 6 -12 -12
    -48 -4 -48 10 0 6 11 8 25 6 13 -3 26 0 29 6 3 7 5 7 5 -1 1 -7 8 -13 16 -13
    8 0 12 -6 9 -14 -4 -9 2 -14 18 -15 43 -1 58 -4 58 -13 0 -5 -12 -8 -27 -6
    -25 3 -28 0 -28 -24 -1 -31 7 -35 24 -15 34 42 144 -37 171 -124 17 -54 22
    -203 10 -284 -5 -33 -12 -80 -15 -105 -11 -80 -44 -210 -61 -238 -51 -82 -73
    -107 -119 -133 -49 -28 -99 -38 -109 -21 -3 5 -19 9 -35 10 -17 0 -43 9 -58
    19 -15 11 -49 33 -75 51 -57 38 -94 34 -52 -5 14 -13 29 -21 32 -18 4 3 0 10
    -8 15 -11 7 -11 10 -2 10 7 0 15 -7 17 -15 15 -49 15 -49 27 -37 18 18 38 14
    38 -5 0 -25 44 -44 116 -50 49 -4 68 -1 103 17 24 12 46 19 49 16 3 -3 0 -6
    -6 -6 -7 0 -12 -5 -12 -12 0 -6 12 -1 26 13 14 13 33 24 42 25 46 3 53 5 48
    13 -3 5 1 11 9 15 8 3 15 1 15 -5 0 -5 8 -9 18 -7 9 2 16 -3 15 -9 -2 -7 4
    -13 13 -13 8 0 13 -4 10 -8 -3 -5 17 -32 43 -60 47 -51 58 -80 37 -94 -12 -9
    13 -30 26 -22 7 5 18 -3 81 -58 18 -15 36 -28 41 -28 4 0 6 -6 3 -13 -3 -8 7
    -18 24 -26 16 -7 26 -17 23 -22 -3 -6 7 -7 25 -4 22 4 36 -1 55 -19 14 -13 26
    -30 26 -38 0 -7 9 -31 20 -53 11 -22 20 -47 20 -57 0 -10 4 -18 9 -18 5 0 7
    -10 4 -22 -3 -17 -2 -19 5 -9 8 11 10 11 14 0 3 -8 1 -22 -3 -31 -6 -10 -5
    -19 1 -23 5 -3 10 -18 10 -32 l0 -26 -25 23 c-14 13 -25 18 -25 12 0 -6 8 -15
    17 -21 10 -5 15 -13 11 -17 -4 -4 -27 10 -52 30 -43 35 -63 46 -52 27 4 -5 1
    -11 -6 -13 -9 -3 -9 -6 0 -12 7 -4 12 -2 12 4 0 6 12 3 28 -7 42 -28 49 -36
    45 -54 -3 -9 1 -20 7 -24 9 -5 10 -1 5 14 -6 20 -5 21 14 9 14 -9 21 -22 20
    -43 l-1 -30 -8 30 c-6 25 -8 26 -9 7 -1 -22 -2 -22 -28 -6 l-28 17 28 -25 c23
    -22 27 -33 25 -67 -2 -22 -5 -41 -8 -41 -4 0 -4 -16 -2 -35 2 -19 0 -35 -4
    -35 -5 0 -9 -28 -10 -63 -1 -93 -12 -140 -31 -133 -12 5 -13 3 -5 -7 11 -13 1
    -127 -18 -195 -9 -35 -7 -52 7 -39 3 4 16 60 29 125 12 64 29 150 38 190 9 40
    17 98 19 130 1 31 7 60 12 64 6 5 5 11 -4 17 -10 6 -10 10 -1 13 7 2 13 30 16
    68 2 36 7 83 10 106 5 33 -1 57 -34 135 -22 51 -42 111 -46 131 -6 35 -10 38
    -67 54 -51 14 -65 23 -84 54 -29 46 -196 243 -278 327 -58 60 -60 62 -45 85
    18 28 35 105 43 203 9 108 13 120 45 139 29 17 43 53 17 43 -11 -4 -45 31 -35
    37 2 1 12 8 22 16 18 14 19 13 13 -3 -4 -12 -3 -16 4 -11 6 3 9 15 6 25 -3 11
    -1 19 5 19 19 0 -5 85 -52 186 -26 56 -53 118 -59 137 -18 54 -38 66 -103 64
    -34 -1 -54 -6 -51 -12 3 -5 0 -5 -7 2 -7 6 -20 9 -29 6 -11 -3 -10 -1 3 7 10
    7 39 13 65 13 25 0 37 2 26 4 -11 3 -26 10 -32 15 -8 6 -32 7 -57 4 -27 -4
    -47 -3 -51 4 -7 12 -55 12 -105 -1z m227 -55 c-12 -12 20 -33 42 -27 18 5 21
    1 20 -23 0 -27 -1 -28 -9 -6 -5 12 -12 22 -14 22 -3 0 -2 -8 1 -17 6 -14 2
    -13 -21 5 -16 12 -38 22 -49 22 -20 0 -21 0 -1 15 18 14 44 21 31 9z m203
    -313 c5 -11 9 -28 9 -38 -1 -17 -2 -17 -6 0 -3 10 -11 16 -18 14 -8 -4 -12 3
    -12 19 0 29 12 31 27 5z m-121 -614 c-10 -23 -46 -43 -57 -32 -3 2 8 20 24 38
    32 38 51 34 33 -6z"/>
    <path d="M988 2565 c-27 -25 -29 -25 -53 -9 -15 10 -25 12 -25 5 0 -6 6 -11
    13 -11 6 0 21 -7 31 -15 19 -14 18 -15 -5 -24 -21 -9 -22 -10 -4 -11 19 -1 18
    -2 -2 -10 -34 -14 -28 -20 8 -10 33 10 44 17 44 28 0 4 13 19 28 35 47 46 13
    67 -35 22z"/>
    <path d="M948 2463 c-10 -2 -18 -9 -18 -14 0 -6 -4 -7 -10 -4 -5 3 -10 -3 -10
    -14 0 -12 -5 -21 -11 -21 -5 0 -7 -4 -4 -10 3 -5 1 -10 -5 -10 -7 0 -10 -6 -7
    -12 2 -7 -3 -21 -11 -31 -12 -13 -13 -20 -4 -29 9 -9 12 -8 12 3 0 9 9 24 20
    34 11 10 20 24 20 31 0 7 11 25 25 40 20 22 34 48 23 43 -2 -1 -11 -3 -20 -6z"/>
    <path d="M983 2270 c-22 -9 -44 -29 -51 -44 -16 -35 -16 -33 15 -41 15 -3 33
    -15 41 -26 7 -10 23 -19 35 -18 22 0 22 0 -1 10 -34 13 -18 31 16 18 22 -9 29
    -7 43 10 15 18 18 19 29 6 17 -20 27 -19 25 3 -1 23 -14 42 -27 42 -6 0 -5 -7
    3 -17 9 -10 9 -14 2 -10 -7 4 -43 8 -80 9 -37 1 -62 3 -55 5 6 3 10 9 7 13 -3
    5 5 16 17 24 20 13 31 14 83 1 67 -15 85 -33 78 -77 -2 -17 -1 -28 5 -24 5 3
    8 -8 7 -25 -2 -27 19 -59 30 -47 3 3 0 41 -6 85 -11 74 -14 82 -43 99 -41 25
    -122 27 -173 4z"/>
    <path d="M1302 2255 c-52 -29 -58 -57 -6 -30 16 8 50 15 76 15 37 0 50 -5 62
    -22 l16 -22 0 21 c0 46 -91 70 -148 38z"/>
    <path d="M1396 2207 c3 -9 -6 -12 -35 -9 -37 4 -81 -10 -81 -26 0 -12 81 -14
    95 -2 9 7 16 6 25 -5 7 -8 16 -12 21 -9 16 9 10 39 -11 51 -15 10 -18 10 -14
    0z"/>
    <path d="M740 2134 c0 -8 5 -12 10 -9 15 9 11 -17 -5 -39 -8 -11 -15 -28 -15
    -38 0 -27 36 -94 55 -101 25 -10 18 -22 -53 -93 -65 -67 -178 -211 -272 -348
    -33 -48 -65 -80 -118 -116 -41 -28 -79 -50 -86 -50 -33 0 -94 -60 -128 -126
    -48 -94 -80 -204 -58 -204 5 0 11 -8 14 -17 4 -10 16 -29 27 -43 11 -14 18
    -19 14 -12 -4 8 -8 33 -10 55 -1 23 -5 52 -9 65 -5 13 -3 22 3 22 6 0 11 9 11
    21 0 14 5 19 14 16 10 -4 12 0 9 14 -4 14 0 19 13 19 16 0 16 2 4 10 -8 5 -11
    13 -6 17 4 4 13 0 20 -9 10 -14 12 -11 12 20 0 19 5 37 12 40 7 2 12 12 12 22
    0 9 6 23 14 30 12 9 17 7 28 -9 7 -12 19 -21 26 -21 8 0 10 5 5 13 -4 6 -8 22
    -8 35 -1 17 4 22 17 19 9 -3 22 1 29 9 6 8 18 14 25 14 8 0 14 5 14 11 0 6 7
    8 16 5 12 -5 15 -2 10 9 -3 9 -2 13 4 10 5 -3 15 3 22 15 7 11 18 20 25 20 7
    0 20 15 29 33 9 17 27 46 39 62 12 17 25 39 29 50 6 16 9 17 16 5 8 -13 10
    -12 10 3 0 9 5 17 10 17 6 0 10 9 10 21 0 11 4 18 9 14 5 -3 17 13 27 35 10
    22 24 40 31 40 7 0 13 7 13 15 0 8 4 15 9 15 5 0 12 9 16 19 3 11 15 23 26 26
    11 4 18 9 15 12 -9 9 34 64 45 58 5 -4 9 2 9 11 0 10 3 15 6 11 3 -3 13 2 22
    11 19 19 28 107 12 117 -5 3 -7 12 -3 20 4 11 1 13 -11 9 -9 -3 -14 -10 -11
    -14 9 -14 -16 -23 -28 -10 -9 9 -8 15 7 25 11 9 15 18 10 27 -5 7 -7 21 -5 32
    3 22 -18 76 -30 76 -5 0 -9 -7 -9 -16z"/>
    <path d="M800 2123 c0 -6 8 -18 18 -25 10 -7 21 -21 25 -30 3 -10 11 -18 18
    -18 6 0 8 7 5 16 -3 9 -6 20 -6 25 0 5 -7 9 -16 9 -9 0 -23 8 -30 18 -7 9 -14
    12 -14 5z"/>
    <path d="M1150 2073 c0 -10 -8 -40 -17 -67 l-18 -48 28 -22 c18 -15 41 -22 62
    -22 38 2 95 39 95 62 0 13 -5 12 -26 -5 -33 -26 -50 -26 -64 -1 -7 13 -21 20
    -40 20 -17 0 -30 4 -30 8 0 14 29 48 30 35 0 -21 30 -15 30 6 0 20 -25 51 -41
    51 -5 0 -9 -8 -9 -17z"/>
    <path d="M1260 2049 c0 -24 4 -38 10 -34 6 3 10 22 10 41 0 19 -4 34 -10 34
    -5 0 -10 -18 -10 -41z"/>
    <path d="M883 2015 c-3 -9 -2 -19 2 -23 4 -4 10 -1 12 6 3 6 9 12 15 12 6 0 6
    -6 -2 -15 -7 -9 -9 -22 -5 -32 7 -15 9 -13 15 12 3 17 4 36 2 43 -6 17 -31 15
    -39 -3z"/>
    <path d="M887 1914 c5 -13 8 -13 15 -2 7 9 8 3 4 -18 -3 -23 0 -33 15 -43 17
    -10 18 -15 8 -27 -10 -12 -9 -16 9 -21 24 -6 28 -18 11 -28 -5 -4 11 -20 37
    -36 25 -17 49 -28 52 -25 2 3 -3 8 -12 12 -9 3 -14 10 -11 14 3 5 -4 16 -16
    24 -12 8 -23 23 -24 33 -3 27 -22 70 -34 78 -6 3 -11 14 -11 25 0 10 -7 21
    -16 24 -26 10 -34 7 -27 -10z"/>
    <path d="M1065 1859 c-11 -16 -1 -20 72 -29 104 -13 205 -12 201 2 -3 7 -43
    12 -113 15 -60 2 -114 8 -119 13 -13 13 -33 13 -41 -1z"/>
    <path d="M1100 1780 c0 -20 15 -28 22 -11 2 5 19 2 38 -6 33 -15 106 -26 95
    -15 -3 3 4 16 16 28 l22 23 -38 -10 c-26 -8 -51 -7 -81 0 -61 16 -74 14 -74
    -9z"/>
    <path d="M850 1597 c0 -9 4 -17 9 -17 5 0 12 -16 15 -35 4 -19 12 -40 19 -47
    7 -7 20 -31 30 -53 10 -21 32 -56 48 -76 29 -37 37 -55 19 -44 -6 3 -7 -1 -4
    -9 3 -9 11 -13 17 -9 6 4 8 -2 5 -15 -4 -12 -2 -25 3 -28 5 -3 9 0 9 6 0 7 6
    5 15 -4 8 -8 15 -22 15 -31 0 -8 7 -18 15 -21 13 -5 13 -9 3 -22 -11 -13 -11
    -14 1 -7 11 6 13 4 8 -8 -4 -11 3 -18 24 -26 30 -10 37 -25 19 -36 -7 -4 -3
    -14 11 -26 12 -10 26 -16 32 -12 7 4 8 -1 3 -13 -4 -10 -4 -15 0 -12 3 4 12 2
    18 -5 14 -15 40 -69 38 -79 -1 -5 4 -8 11 -8 16 0 17 -41 2 -53 -6 -5 -4 -7 5
    -4 8 2 13 12 12 23 -2 10 -1 16 4 12 4 -4 8 -20 9 -35 4 -58 4 -58 20 -37 15
    18 15 18 9 -7 -3 -17 0 -32 11 -42 9 -9 13 -22 9 -28 -4 -8 -3 -9 5 -5 14 9
    43 -12 35 -25 -3 -5 1 -12 10 -15 8 -3 17 -12 20 -21 8 -20 -26 -12 -150 37
    -78 30 -100 44 -106 62 -4 16 -6 18 -7 6 -1 -10 -6 -18 -12 -18 -15 0 -122 85
    -137 110 -7 11 -19 20 -27 20 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -12 0 -13
    -4 -6 -19 6 -10 7 -22 3 -26 -4 -5 -1 -5 6 -1 8 5 12 -1 12 -19 0 -15 4 -24
    10 -20 5 3 22 -5 37 -19 24 -23 39 -31 115 -67 15 -7 36 -20 47 -30 10 -10 22
    -15 25 -12 3 4 15 -3 26 -15 21 -23 36 -30 25 -12 -3 6 -2 10 4 10 5 0 14 -6
    18 -14 4 -7 42 -26 83 -40 78 -28 110 -50 110 -72 0 -8 -10 -13 -22 -13 -49 3
    -204 -14 -215 -23 -7 -5 -13 -5 -17 0 -3 5 -22 7 -42 5 -23 -2 -40 1 -48 11
    -6 7 -28 17 -48 20 -33 6 -38 11 -39 34 0 15 -4 36 -8 47 -6 14 -8 5 -7 -27 0
    -27 4 -48 8 -48 4 0 3 -12 -2 -26 -7 -16 -7 -23 0 -19 6 3 10 13 10 21 0 8 5
    14 11 14 6 0 8 -7 5 -16 -5 -12 -2 -15 10 -10 10 4 14 2 11 -6 -2 -7 -11 -12
    -19 -12 -10 1 -14 -5 -10 -18 4 -16 7 -17 21 -5 26 22 49 27 55 11 4 -10 17
    -13 48 -9 74 7 202 27 210 31 5 3 11 -2 14 -10 8 -21 22 -20 26 1 2 9 13 17
    26 18 30 2 25 -29 -6 -33 -12 -2 -22 -8 -22 -13 0 -14 62 0 72 15 4 6 5 18 2
    26 -20 55 -17 66 24 79 22 8 46 10 52 6 8 -5 11 -4 6 3 -3 6 3 13 14 17 11 3
    20 11 20 17 0 5 14 28 31 50 17 21 28 42 25 46 -4 3 -10 2 -14 -4 -36 -54 -73
    -89 -113 -110 -27 -13 -52 -24 -58 -24 -12 0 -41 46 -46 72 -4 16 -148 265
    -334 573 -13 20 -10 8 6 -32 4 -11 3 -14 -5 -9 -7 4 -12 11 -12 16 0 10 -23
    45 -79 120 -18 25 -46 74 -63 109 -16 36 -36 74 -44 85 -12 18 -14 18 -14 3z"/>
    <path d="M920 1568 c0 -10 33 -82 41 -88 4 -3 9 -14 13 -26 7 -22 59 -79 73
    -79 4 -1 30 -11 57 -23 27 -12 55 -22 62 -22 8 0 53 20 101 45 94 49 118 54
    131 28 14 -27 51 -193 54 -238 1 -22 6 -43 10 -46 14 -9 36 -170 33 -244 -3
    -74 -20 -151 -34 -157 -5 -2 -12 -14 -16 -28 -8 -25 -7 -25 13 -7 11 10 26 32
    32 48 7 16 16 26 21 23 5 -3 6 1 4 9 -3 7 1 29 8 48 13 29 13 49 1 138 -8 57
    -20 128 -27 157 -7 29 -15 81 -16 115 -1 35 -5 68 -9 73 -3 6 -7 31 -8 57 -1
    26 -10 59 -19 73 -10 14 -13 27 -9 27 5 1 -7 5 -26 8 -19 4 -41 3 -48 -3 -9
    -7 -16 -5 -23 5 -6 10 -9 10 -9 2 0 -7 -7 -13 -15 -13 -8 0 -15 -7 -15 -15 0
    -8 -9 -15 -19 -15 -11 0 -23 -4 -26 -10 -3 -5 -13 -10 -21 -10 -9 0 -22 -7
    -31 -15 -12 -12 -27 -14 -62 -9 -25 3 -50 11 -56 16 -6 6 -3 7 10 3 19 -6 19
    -6 2 5 -10 6 -25 9 -33 6 -8 -3 -14 1 -14 9 0 8 -4 15 -10 15 -5 0 -10 -5 -10
    -11 0 -6 -7 -3 -17 6 -14 14 -14 18 -2 27 11 8 10 9 -3 4 -17 -5 -45 28 -40
    47 1 4 -2 13 -8 20 -6 7 -17 21 -25 32 -8 11 -15 17 -15 13z"/>
    <path d="M1500 1479 c-13 -8 -13 -10 0 -19 13 -8 12 -10 -5 -11 -11 0 -15 -3
    -8 -5 7 -3 9 -12 6 -20 -3 -8 0 -14 5 -14 6 0 9 -9 7 -20 -2 -11 0 -20 6 -20
    5 0 9 -8 9 -17 0 -14 10 -51 28 -103 3 -8 6 -17 7 -20 0 -3 8 -30 17 -60 9
    -30 21 -92 28 -138 6 -46 20 -103 30 -128 15 -34 17 -50 9 -71 -8 -20 -6 -30
    8 -45 15 -17 18 -38 18 -120 0 -93 -6 -121 -19 -99 -4 5 -32 17 -64 26 -31 9
    -65 19 -75 22 -16 4 -17 2 -8 -15 7 -14 29 -23 75 -32 66 -12 88 -33 62 -61
    -8 -9 -8 -10 2 -5 9 5 12 -4 12 -32 0 -46 24 -76 33 -41 3 12 2 32 -3 44 -5
    13 -7 36 -6 52 2 15 4 45 5 66 1 41 17 50 26 15 11 -45 11 -98 1 -98 -6 0 -6
    -4 2 -8 7 -5 10 -22 8 -40 -2 -18 0 -35 5 -38 15 -9 10 -34 -6 -34 -11 0 -15
    -10 -14 -37 1 -36 2 -36 8 -9 3 16 11 31 18 33 9 3 11 -21 9 -97 -3 -96 4
    -130 19 -92 8 21 4 246 -5 289 -5 19 -3 36 3 42 8 8 7 11 -2 11 -7 0 -10 3 -8
    7 5 8 -26 219 -38 263 -4 14 -8 33 -9 42 -1 10 -5 21 -9 24 -4 4 -7 18 -7 30
    0 13 -4 25 -9 28 -5 4 -9 21 -8 39 1 17 -4 38 -10 45 -7 7 -14 25 -15 40 -1
    32 -3 42 -11 62 -13 35 -14 59 -3 64 7 3 7 5 -1 5 -7 1 -13 10 -13 21 0 11 -5
    20 -12 20 -9 0 -9 3 -1 11 12 12 10 15 -25 55 -8 8 -11 22 -8 30 3 9 3 21 -1
    27 -4 7 -8 17 -9 22 -1 6 -8 24 -15 40 -6 17 -14 41 -16 55 -5 31 -5 31 -23
    19z"/>
    <path d="M1242 1328 c-24 -5 -41 -12 -39 -15 9 -9 107 8 101 18 -6 10 -4 10
    -62 -3z"/>
    <path d="M1717 1323 c-4 -6 -5 -18 -2 -25 3 -8 -1 -21 -10 -28 -19 -16 -19
    -26 0 -34 21 -8 45 3 45 22 0 13 -3 12 -14 -4 -11 -14 -18 -16 -26 -8 -9 9 -7
    15 7 25 14 10 18 21 13 39 -4 15 -9 20 -13 13z"/>
    <path d="M437 1313 c-15 -14 -5 -20 31 -17 55 3 59 5 37 14 -25 11 -59 12 -68
    3z"/>
    <path d="M1767 1293 c-12 -12 -7 -23 11 -24 9 0 12 -3 5 -5 -8 -4 -7 -11 5
    -25 9 -10 20 -19 25 -19 5 0 1 7 -8 16 -12 13 -13 19 -4 30 7 8 9 17 5 21 -3
    3 -6 0 -6 -8 -1 -11 -4 -10 -14 3 -7 10 -16 15 -19 11z"/>
    <path d="M1782 1201 c-17 -10 -5 -61 14 -61 19 0 18 26 -2 34 -13 5 -14 9 -4
    21 14 17 11 19 -8 6z"/>
    <path d="M864 1158 c4 -18 7 -43 6 -55 0 -23 15 -39 24 -25 6 9 -23 112 -31
    112 -3 0 -2 -15 1 -32z"/>
    <path d="M1834 1157 c3 -10 6 -21 6 -23 0 -2 7 -4 15 -4 8 0 15 -4 15 -10 0
    -5 10 -14 23 -20 20 -10 20 -10 5 3 -10 8 -18 22 -18 31 0 10 -5 14 -12 10 -6
    -4 -18 2 -26 12 -14 18 -14 18 -8 1z"/>
    <path d="M248 1053 c6 -2 10 -8 7 -13 -3 -4 4 -28 15 -51 11 -24 20 -38 20
    -30 0 11 3 11 18 0 16 -13 16 -13 2 6 -8 11 -21 25 -29 31 -8 6 -11 16 -7 23
    9 14 -11 41 -28 40 -7 0 -6 -2 2 -6z"/>
    <path d="M470 1052 c0 -15 25 -62 33 -62 4 0 7 -10 7 -22 0 -12 3 -18 7 -15 3
    4 15 -7 25 -24 16 -26 17 -34 6 -40 -10 -7 -9 -9 3 -9 9 0 25 -12 35 -26 10
    -15 29 -38 41 -51 12 -14 19 -31 16 -39 -4 -10 3 -14 23 -14 16 0 25 -4 19 -8
    -21 -15 -16 -34 7 -27 23 7 23 7 3 -9 -11 -8 -14 -16 -7 -16 6 0 12 -7 12 -15
    0 -8 6 -15 13 -15 20 0 51 -37 38 -45 -6 -3 -11 -11 -11 -18 0 -6 6 -2 14 9
    14 18 15 18 22 -11 4 -17 15 -37 26 -46 27 -24 23 -47 -6 -42 -18 4 -23 10
    -19 22 4 12 3 13 -6 5 -15 -14 -3 -47 15 -40 18 7 18 -23 0 -43 -12 -14 -9
    -21 27 -56 l40 -40 -32 39 c-18 21 -31 40 -29 42 2 2 21 -3 41 -10 20 -7 34
    -15 31 -19 -7 -7 21 -44 89 -119 27 -29 55 -68 63 -87 11 -24 18 -31 26 -23 8
    8 3 28 -20 72 -41 77 -56 104 -62 110 -3 3 -14 21 -24 40 -10 19 -36 58 -57
    87 -22 29 -39 56 -39 61 0 5 -11 19 -25 32 -14 13 -25 29 -25 37 0 7 -4 13
    -10 13 -5 0 -10 7 -10 16 0 11 -5 14 -16 10 -13 -5 -15 -2 -11 14 6 23 -3 26
    -23 8 -12 -11 -12 -10 -1 4 15 20 12 26 -46 94 -24 28 -43 56 -43 61 0 5 -9
    18 -21 29 -12 10 -28 32 -37 49 -8 16 -22 35 -30 41 -9 7 -12 16 -7 25 5 8 5
    11 0 7 -4 -4 -17 10 -27 32 -18 36 -38 53 -38 32z m400 -599 c0 -16 -2 -16
    -34 -3 -27 10 -35 35 -14 42 15 5 48 -22 48 -39z"/>
    <path d="M1796 1035 c4 -23 3 -26 -5 -15 -6 8 -11 11 -11 5 0 -5 16 -23 35
    -38 19 -15 35 -23 35 -18 0 6 -9 15 -20 21 -11 6 -20 18 -20 28 0 9 -5 24 -10
    32 -8 11 -9 7 -4 -15z"/>
    <path d="M896 1025 c4 -8 1 -15 -5 -15 -8 0 -8 -4 1 -14 11 -12 14 -11 21 6 7
    21 2 38 -13 38 -5 0 -7 -7 -4 -15z"/>
    <path d="M185 998 c17 -28 45 -38 45 -16 0 6 10 3 22 -8 l21 -19 -18 29 c-11
    16 -21 24 -23 17 -5 -16 -22 -14 -45 7 -20 17 -20 17 -2 -10z"/>
    <path d="M32 947 c-12 -29 -21 -68 -21 -87 1 -31 2 -29 9 13 5 27 15 56 23 65
    19 23 28 50 18 56 -4 3 -17 -18 -29 -47z"/>
    <path d="M1770 978 c0 -14 9 -33 20 -43 11 -10 20 -13 20 -8 0 6 -7 13 -15 17
    -8 3 -15 11 -15 17 0 8 4 8 15 -1 23 -19 18 4 -5 25 -19 18 -20 17 -20 -7z"/>
    <path d="M1328 958 c-14 -20 -14 -25 -1 -45 9 -13 24 -23 34 -23 16 0 15 3 -7
    25 -14 14 -22 30 -19 35 10 16 21 12 32 -12 5 -12 12 -17 16 -12 7 12 -19 54
    -32 54 -5 0 -15 -10 -23 -22z"/>
    <path d="M230 961 c0 -7 -5 -9 -12 -5 -6 4 -9 3 -7 -2 3 -5 11 -21 18 -36 8
    -15 19 -24 24 -21 6 4 8 0 4 -11 -4 -10 -3 -15 3 -11 6 3 10 2 10 -4 0 -5 -4
    -13 -10 -16 -5 -3 -10 -12 -9 -18 0 -9 2 -9 5 -1 3 7 38 -20 79 -60 74 -70 75
    -72 73 -118 -2 -32 3 -53 15 -66 14 -15 16 -27 8 -67 -6 -30 -5 -58 0 -73 l9
    -25 21 24 c11 13 17 30 14 38 -4 9 -9 41 -11 71 -3 30 -10 58 -17 63 -8 6 -7
    7 5 3 12 -3 15 -1 11 9 -3 8 -10 12 -15 9 -6 -4 -8 4 -6 19 2 18 -14 45 -62
    103 -74 91 -82 97 -92 81 -5 -7 -8 -2 -8 13 -1 27 -23 64 -33 53 -4 -3 -9 2
    -13 11 -10 26 0 28 24 6 12 -12 22 -17 22 -13 -1 20 -50 63 -50 44z"/>
    <path d="M130 914 c0 -6 7 -17 15 -24 8 -7 15 -9 15 -4 0 4 7 -1 15 -12 16
    -19 16 -19 13 1 -2 11 -10 20 -18 20 -8 0 -20 7 -27 15 -7 9 -12 10 -13 4z"/>
    <path d="M100 902 c0 -6 14 -27 30 -45 17 -19 30 -41 30 -49 0 -19 32 -48 52
    -48 8 0 23 -9 33 -20 22 -25 9 -26 -19 -3 -16 14 -16 12 3 -9 13 -15 29 -24
    37 -21 8 3 24 -3 34 -12 11 -10 20 -15 20 -11 0 4 -11 17 -25 30 -13 13 -22
    26 -20 29 3 4 2 5 -1 2 -4 -2 -18 4 -31 15 -13 10 -30 17 -37 14 -7 -3 -19 3
    -27 12 -13 16 -12 17 9 12 33 -9 27 7 -13 32 -19 12 -35 25 -35 29 0 4 -9 18
    -20 31 -11 13 -20 18 -20 12z"/>
    <path d="M1810 893 c0 -11 90 -87 96 -80 2 2 -11 16 -30 30 -19 15 -41 34 -50
    43 -9 8 -16 12 -16 7z"/>
    <path d="M80 873 c0 -5 14 -22 31 -39 23 -21 29 -23 21 -9 -13 25 -52 62 -52
    48z"/>
    <path d="M353 853 c9 -11 17 -23 17 -27 0 -4 7 -14 15 -22 13 -14 15 -13 15 7
    0 12 -3 19 -7 16 -3 -4 -11 3 -17 15 -6 12 -18 24 -26 26 -12 4 -11 1 3 -15z"/>
    <path d="M700 840 c-12 -16 -16 -30 -10 -30 6 0 8 -5 4 -12 -5 -8 0 -7 11 2
    14 12 19 12 23 2 3 -10 7 -9 16 4 8 10 15 13 19 7 4 -6 -6 -23 -21 -38 -23
    -24 -24 -26 -5 -16 13 6 27 20 33 31 6 11 18 20 26 20 8 0 12 5 9 10 -8 12
    -52 13 -59 1 -3 -5 -15 -7 -26 -4 -19 5 -20 7 -5 29 22 35 10 29 -15 -6z"/>
    <path d="M941 857 c-1 -10 -5 -15 -11 -12 -16 10 -12 -11 5 -25 10 -8 13 -21
    9 -33 -4 -12 -2 -17 4 -13 5 3 13 -5 16 -19 5 -20 12 -25 39 -25 l32 1 -32 14
    c-19 8 -33 22 -34 32 -1 27 -27 101 -28 80z"/>
    <path d="M90 823 c0 -14 191 -193 205 -193 5 0 -6 15 -25 32 -19 18 -68 64
    -107 102 -40 38 -73 64 -73 59z"/>
    <path d="M350 715 c0 -8 4 -15 10 -15 5 0 7 -6 4 -14 -3 -8 -1 -17 5 -21 12
    -7 15 23 5 49 -8 20 -24 21 -24 1z"/>
    <path d="M855 720 c-4 -6 -10 -8 -14 -6 -4 3 -13 -5 -21 -17 -12 -20 -12 -21
    4 -8 22 18 31 8 11 -13 -9 -9 -11 -16 -5 -16 6 0 17 14 24 30 7 17 16 27 21
    23 4 -5 5 -2 1 5 -9 15 -13 15 -21 2z"/>
    <path d="M1797 658 c4 -19 3 -20 -6 -8 -9 12 -11 11 -11 -9 0 -13 11 -36 25
    -53 14 -16 25 -25 25 -20 0 5 -9 17 -20 27 -23 21 -27 40 -7 32 10 -4 13 2 9
    19 -2 13 -8 26 -12 29 -5 3 -6 -5 -3 -17z"/>
    <path d="M876 654 c-3 -8 -2 -13 3 -9 5 3 12 -1 14 -7 4 -10 6 -10 6 0 1 6 9
    12 19 12 14 0 15 2 2 10 -22 14 -37 12 -44 -6z"/>
    <path d="M470 604 c0 -3 9 -18 19 -33 11 -16 18 -36 15 -48 -3 -11 1 -25 10
    -32 19 -16 21 -35 4 -25 -10 5 -10 4 -1 -6 6 -7 16 -9 22 -5 8 4 5 17 -9 40
    -12 20 -17 36 -11 39 5 4 13 0 18 -7 4 -8 22 -21 39 -30 17 -9 36 -23 43 -31
    7 -9 17 -13 22 -10 5 3 20 -7 34 -22 19 -20 25 -23 25 -10 0 22 -38 59 -55 52
    -8 -3 -17 0 -21 6 -4 7 -3 8 4 4 7 -4 12 -3 12 2 0 6 -7 12 -15 16 -8 3 -15 1
    -15 -5 0 -6 -27 11 -60 36 -33 25 -60 53 -60 61 0 8 -4 14 -10 14 -5 0 -10 -3
    -10 -6z"/>
    <path d="M1305 540 c-27 -7 -25 -8 18 -9 30 -1 46 3 42 9 -7 11 -16 11 -60 0z"/>
    <path d="M940 530 c0 -6 -7 -10 -16 -8 -12 2 -18 -5 -19 -21 -2 -22 -1 -23 12
    -6 13 18 13 18 14 -1 1 -10 1 -22 1 -25 -1 -4 7 -9 16 -11 12 -3 18 1 18 12 0
    10 -6 16 -12 13 -8 -3 -10 6 -8 26 2 17 1 31 -1 31 -3 0 -5 -5 -5 -10z"/>
    <path d="M1540 515 c0 -8 4 -16 8 -19 14 -9 65 9 70 25 3 11 2 11 -8 2 -8 -7
    -22 -8 -41 -3 -22 7 -29 5 -29 -5z"/>
    <path d="M716 505 c-3 -8 -1 -15 4 -15 5 0 18 -10 30 -22 11 -12 20 -16 20 -9
    0 17 -26 42 -35 33 -5 -4 -5 1 -2 11 8 21 -8 23 -17 2z"/>
    <path d="M670 507 c0 -12 69 -87 73 -80 2 5 -13 25 -34 46 -22 21 -39 36 -39
    34z"/>
    <path d="M1142 496 c11 -8 14 -16 7 -23 -6 -6 3 -8 25 -7 20 2 38 -1 41 -6 3
    -5 15 -6 28 -1 12 4 57 8 99 7 64 -1 81 -5 102 -23 15 -13 27 -23 29 -23 1 0
    1 18 -1 41 -3 29 -7 38 -15 30 -14 -14 -30 -14 -22 -1 4 6 -5 8 -22 5 -15 -3
    -76 -7 -135 -9 -84 -3 -110 -1 -119 10 -6 8 -17 14 -23 14 -6 0 -3 -6 6 -14z"/>
    <path d="M550 443 c0 -16 103 -68 138 -69 18 -1 31 0 30 1 -16 13 -49 25 -44
    16 4 -6 4 -11 1 -11 -8 0 -76 37 -102 56 -13 9 -23 12 -23 7z"/>
    <path d="M1006 441 c-5 -7 -3 -18 3 -24 6 -6 11 -21 11 -34 0 -13 5 -23 10
    -23 12 0 12 -4 -4 49 -8 29 -15 40 -20 32z"/>
    <path d="M1370 436 c0 -7 10 -20 23 -29 20 -13 26 -13 42 -1 18 14 18 14 -2
    14 -12 0 -28 7 -37 15 -18 19 -26 19 -26 1z"/>
    <path d="M415 392 c27 -24 124 -93 165 -116 8 -4 1 6 -17 23 -18 17 -35 31
    -38 31 -3 0 -11 4 -18 10 -38 31 -108 80 -115 80 -4 0 7 -12 23 -28z"/>
    <path d="M500 390 c19 -16 39 -30 45 -30 11 0 -30 35 -60 51 -11 5 -4 -4 15
    -21z"/>
    <path d="M1050 408 c0 -8 55 -48 65 -48 4 0 -3 9 -16 20 -20 16 -21 19 -6 20
    9 0 17 4 17 9 0 4 -13 8 -30 7 -16 -1 -30 -4 -30 -8z"/>
    <path d="M1240 404 l-55 -13 65 4 c36 2 75 4 88 4 12 1 22 5 22 11 0 12 -50
    10 -120 -6z"/>
    <path d="M1470 373 c0 -32 -14 -58 -26 -50 -5 3 -34 -1 -64 -8 -52 -13 -53
    -13 -17 -14 29 -1 37 -5 37 -19 0 -22 19 -42 40 -42 12 1 12 2 -2 11 -26 15
    -23 41 5 37 17 -2 23 -11 27 -43 5 -38 6 -39 18 -17 10 16 10 27 2 43 -7 11
    -9 42 -6 69 3 27 1 52 -4 55 -6 4 -10 -6 -10 -22z"/>
    <path d="M1673 393 c-15 -5 -18 -69 -4 -77 8 -6 27 72 19 78 -1 2 -9 1 -15 -1z"/>
    <path d="M592 371 c4 -15 107 -75 115 -68 5 5 -60 47 -72 47 -5 0 -17 8 -28
    18 -15 12 -19 13 -15 3z"/>
    <path d="M1695 300 c-4 -6 -10 -8 -15 -5 -9 6 -21 -35 -14 -47 3 -4 14 -4 24
    2 12 7 16 17 13 31 -4 15 -3 20 5 15 7 -4 12 -2 12 3 0 14 -17 14 -25 1z"/>
    <path d="M660 295 c0 -11 65 -64 78 -65 8 0 -5 16 -28 35 -42 35 -50 40 -50
    30z"/>
    <path d="M1062 50 c10 -29 25 -46 34 -38 6 7 -26 68 -36 68 -5 0 -4 -14 2 -30z"/>
    </g>
    </svg>
);

export default Teddy;
