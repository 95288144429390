import React from "react";

const Party = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 256 256"
    preserveAspectRatio="xMidYMid meet"
  >
    <path d="M87.002,70.047c4.24,10.293,16.021,15.2,26.315,10.96c6.352-2.617,10.643-8.108,12.009-14.347l16.375,4.657l2.249-6.608
        l-16.786-11.215l1.627-13.675c0.305-2.567-1.299-4.975-3.785-5.682l-21.375-6.079c-2.486-0.707-5.118,0.497-6.209,2.84
        l-5.668,12.175L72.12,44.281l-2.249,6.607l16.784,4.774C85.058,60.178,85.034,65.269,87.002,70.047z M127.515,131.464H103.24
        L90.648,85.99c-1.821-8.031-8.992-14.15-17.406-14.15H28.81c-3.065,0-6.129,1.628-7.849,4.309L3.343,106.947
        c-2.68,4.299-0.961,9.699,3.257,12.339c3.843,2.397,9.538,0.931,12.329-3.065l15.04-26.115h23.303L28.588,139.57
        c-3.346,5.771-3.663,12.812-0.848,18.86l14.36,30.856l-12.663,48.457c-1.77,6.999,2.397,14.018,9.214,15.849
        c6.999,1.77,14.008-2.397,15.849-9.214l13.401-53.029c0.718-2.721,0.627-5.947-0.809-8.678l-1.224-2.589l17.204,9.912L66.1,236.236
        c-2.468,6.706,0.708,14.362,7.373,16.901c6.766,2.579,14.221-0.819,16.719-7.596l20.744-56.69
        c2.569-7.009-0.293-14.827-6.766-18.529l-36.664-20.926l14.342-25.053l5.234,18.492c1.184,4.05,4.898,6.834,9.117,6.834h31.314
        c5.065,0.183,8.862-3.65,9.052-8.616C136.739,136.519,132.368,131.908,127.515,131.464z M190.205,60.283
        c16.823-6.387,25.937-24.044,22.136-41.086c-0.049,0.876-0.442,1.597-0.804,2.401c-2.273,5.056-8.353,7.364-13.409,5.09
        c-0.804-0.362-1.557-0.838-2.195-1.263c-5.544-4.565-13.392-6.16-20.387-3.504c-10.494,3.984-16.014,15.798-11.935,26.542
        C167.69,59.206,179.462,64.361,190.205,60.283z M253.942,204.308c0,0-4.046-64.753-29.837-88.016
        c-0.17-0.153-0.337-0.295-0.506-0.443V69.487l21.414-9.347c5.463-2.386,8.391-8.52,6.81-14.269L241.496,8.319
        c-1.258-4.578-5.99-7.268-10.569-6.01c-4.578,1.259-7.269,5.991-6.009,10.569l9.151,33.277l-18.992,8.29l-38.175,16.952
        c-7.452,3.31-12.559,10.408-13.325,18.525l-1.897,20.062l-22.306-12.783c-4.12-2.361-9.373-0.936-11.734,3.184
        c-2.361,4.12-0.935,9.373,3.184,11.734l30.013,17.2c1.772,1.016,3.736,1.526,5.703,1.526c1.805,0,3.612-0.429,5.275-1.29
        c3.478-1.801,5.775-5.204,6.146-9.106l1.797-19.007l8.947,20.92c0,0,1.934,13.718-22.757,31.354
        c-28.32,20.228-18.711,62.202-18.711,62.202s8.44-2.941,20.734-4.623v31.425c0.008,6.093,5.169,11.046,11.126,11.126
        c6.093-0.008,11.038-5.166,11.126-11.126v-32.395c4.144,0.236,8.392,0.733,12.656,1.583l-0.259,0.118l8.408,33.344
        c1.498,5.906,7.713,9.447,13.508,8.068c5.906-1.498,9.44-7.708,8.068-13.508l-6.186-24.531
        C250.962,216.192,253.942,204.308,253.942,204.308z"/>
    </svg>
);

export default Party;