import React from "react";

const Beers = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 300.000000 119.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,220.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M1480 2550 c0 -183 1 -190 20 -190 19 0 20 7 20 190 0 183 -1 190
    -20 190 -19 0 -20 -7 -20 -190z"/>
    <path d="M1145 2630 c-9 -15 74 -260 92 -267 34 -13 32 16 -10 142 -42 124
    -63 156 -82 125z"/>
    <path d="M1815 2628 c-3 -7 -23 -67 -45 -133 -39 -116 -41 -145 -7 -132 17 6
    101 252 92 267 -9 14 -35 12 -40 -2z"/>
    <path d="M869 2474 c-13 -16 -14 -14 80 -116 42 -45 76 -59 76 -31 0 14 -114
    149 -132 157 -6 2 -17 -2 -24 -10z"/>
    <path d="M2036 2415 c-67 -74 -81 -105 -48 -105 21 0 142 131 142 153 0 38
    -32 22 -94 -48z"/>
    <path d="M1158 2270 c-40 -12 -95 -48 -118 -77 -18 -23 -23 -25 -46 -15 -59
    28 -130 8 -178 -48 -21 -26 -26 -42 -26 -88 -1 -78 -34 -208 -51 -203 -139 37
    -193 33 -227 -17 -36 -53 -152 -371 -152 -418 0 -91 52 -164 153 -215 32 -17
    57 -33 55 -37 -2 -4 -28 -56 -58 -114 -51 -99 -54 -109 -39 -124 15 -14 773
    -204 818 -204 37 0 41 19 41 185 l-1 160 57 241 57 241 48 -5 c31 -3 54 0 64
    8 9 7 18 11 21 8 3 -3 30 -108 61 -234 l57 -229 1 -175 c1 -97 5 -181 9 -188
    11 -19 41 -15 242 33 361 86 439 105 518 124 115 28 119 41 46 173 -27 50 -50
    96 -50 102 0 6 22 21 49 33 103 47 154 117 155 213 1 45 -11 86 -67 230 -38
    96 -78 185 -89 197 -36 39 -77 46 -158 27 -39 -9 -73 -14 -74 -12 -9 11 -46
    170 -46 199 0 99 -137 172 -232 123 -29 -15 -33 -15 -69 5 -82 46 -195 37
    -266 -23 -52 -43 -86 -45 -121 -8 -16 17 -48 35 -72 42 -24 6 -58 22 -75 35
    -74 57 -163 77 -237 55z m146 -54 c18 -7 48 -28 68 -45 23 -20 46 -31 65 -31
    17 0 42 -9 57 -21 l27 -21 -44 -28 c-51 -32 -110 -101 -133 -156 -9 -21 -20
    -66 -23 -99 l-6 -60 -45 1 c-62 0 -130 37 -192 102 -45 47 -57 55 -97 59 -107
    11 -170 88 -137 166 17 42 81 72 121 57 21 -8 24 -15 22 -47 -3 -54 22 -50 49
    7 24 50 63 90 109 114 37 20 115 21 159 2z m597 -86 c28 -15 46 -34 61 -65 12
    -25 28 -45 35 -45 22 0 27 32 10 62 -15 27 -15 28 5 39 55 30 148 -3 169 -59
    14 -36 6 -48 -38 -55 -31 -6 -42 -14 -61 -47 -36 -64 -92 -84 -193 -67 l-46 8
    -11 -43 c-16 -64 -45 -86 -117 -90 -54 -3 -60 -5 -65 -28 -3 -14 -25 -38 -47
    -55 -29 -20 -42 -38 -42 -54 -2 -60 -67 -80 -119 -36 -74 63 -98 196 -53 301
    42 96 147 174 236 174 20 0 39 8 51 21 55 62 148 78 225 39z m323 -283 c145
    -624 151 -642 236 -800 55 -102 57 -107 37 -112 -12 -3 -107 -26 -212 -51
    -104 -25 -262 -63 -350 -84 -88 -21 -169 -41 -179 -46 -18 -7 -19 -2 -13 107
    7 153 -3 223 -73 508 -33 133 -60 248 -60 255 0 7 11 18 25 24 14 7 33 25 43
    41 13 24 24 30 65 34 59 5 96 30 121 82 l18 40 72 0 c61 0 78 4 109 25 20 14
    44 39 54 57 9 18 24 34 33 37 42 11 45 8 74 -117z m-1384 84 c0 -15 101 -61
    133 -61 22 0 40 -12 73 -49 61 -68 148 -111 225 -111 55 0 58 -1 64 -29 3 -17
    20 -48 36 -70 16 -23 29 -46 29 -51 0 -6 -26 -115 -57 -243 -56 -230 -57 -234
    -59 -397 -1 -91 -6 -164 -11 -164 -4 1 -37 9 -73 17 -320 78 -528 128 -595
    143 -44 9 -83 20 -88 24 -4 4 16 49 44 101 79 141 90 176 180 551 46 192 87
    348 92 348 4 0 7 -4 7 -9z m-160 -125 c37 -8 56 -18 58 -29 4 -21 6 -21 -74
    -1 -56 14 -67 15 -78 3 -7 -8 -41 -86 -76 -174 -89 -228 -85 -268 33 -330 43
    -23 57 -35 57 -52 0 -28 0 -28 -59 1 -75 39 -108 71 -126 127 -21 61 -20 63
    61 271 81 209 85 212 204 184z m1788 -3 c27 -25 144 -327 149 -388 7 -78 -24
    -127 -111 -177 -53 -30 -71 -36 -77 -26 -15 22 -11 27 51 58 69 35 100 76 100
    132 0 50 -128 376 -150 383 -10 3 -44 -1 -76 -10 -55 -14 -67 -12 -65 12 1 10
    84 30 127 32 18 0 41 -7 52 -16z m-1793 -75 c25 -7 45 -16 45 -21 0 -13 -69
    -305 -81 -341 -5 -16 -9 -40 -9 -52 0 -31 -16 -30 -74 2 -82 46 -84 65 -15
    239 28 72 55 142 61 158 11 31 12 31 73 15z m1799 -143 c32 -85 59 -169 58
    -186 0 -17 -10 -42 -22 -54 -21 -22 -97 -62 -103 -54 -2 2 -25 94 -52 204 -26
    110 -50 205 -52 211 -3 10 56 30 97 33 10 0 33 -46 74 -154z"/>
    <path d="M1601 2022 c-47 -23 -73 -62 -75 -109 -1 -31 2 -38 19 -38 14 0 21 9
    26 32 8 42 26 63 67 80 34 14 42 37 16 47 -20 8 -13 9 -53 -12z"/>
    <path d="M2130 1771 c0 -25 86 -380 93 -388 4 -3 14 -3 23 1 13 5 15 14 9 44
    -7 37 -71 311 -81 345 -7 24 -44 22 -44 -2z"/>
    <path d="M2247 1302 c-3 -4 2 -39 12 -78 14 -57 21 -70 39 -72 27 -4 27 9 4
    93 -11 39 -23 61 -34 63 -9 2 -19 -1 -21 -6z"/>
    <path d="M2301 1075 c-108 -62 -285 -105 -433 -105 -51 0 -58 -2 -58 -20 0
    -29 36 -34 157 -21 124 13 218 38 326 89 63 29 77 39 75 56 -4 29 -18 29 -67
    1z"/>
    <path d="M850 1734 c-6 -14 -10 -29 -10 -34 0 -5 -27 -121 -60 -257 -34 -137
    -59 -257 -58 -268 2 -11 10 -20 18 -20 11 0 22 30 41 105 80 318 118 484 113
    491 -11 18 -35 8 -44 -17z"/>
    <path d="M662 1087 c-16 -19 -4 -31 63 -64 69 -35 166 -67 245 -82 84 -17 226
    -18 241 -3 22 21 -2 29 -111 35 -146 8 -288 50 -421 124 -3 2 -11 -2 -17 -10z"/>
    </g>
    </svg>
);

export default Beers;