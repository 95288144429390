import React from "react";

const Mail = ({ color }) => (
    <svg
    version="1.0" 
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width="100.000000pt" 
    height="100.000000pt" 
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M0 815 c0 -25 6 -54 12 -65 10 -15 10 -22 0 -32 -17 -17 -17 -419 0
    -436 10 -10 10 -17 0 -32 -6 -11 -12 -40 -12 -65 l0 -45 500 0 500 0 0 45 c0
    25 -6 54 -12 65 -10 15 -10 22 0 32 17 17 17 419 0 436 -10 10 -10 17 0 32 6
    11 12 40 12 65 l0 45 -500 0 -500 0 0 -45z m960 -20 c0 -16 -12 -36 -32 -54
    -18 -15 -111 -96 -207 -180 -96 -83 -185 -154 -197 -157 -13 -4 -35 -3 -48 0
    -13 3 -102 74 -198 158 -95 84 -188 165 -206 180 -21 17 -32 36 -32 52 l0 26
    460 0 460 0 0 -25z m-732 -240 c39 -33 70 -63 69 -67 -1 -3 -59 -51 -129 -107
    l-128 -101 0 221 0 221 58 -53 c32 -30 91 -81 130 -114z m732 -55 l0 -220
    -127 101 c-71 56 -129 105 -130 108 -1 3 39 40 88 81 49 41 106 92 126 113 21
    20 38 37 40 37 2 0 3 -99 3 -220z m-574 -85 c89 -72 144 -71 234 5 l49 41 145
    -113 c122 -96 145 -117 145 -140 l1 -28 -460 0 -460 0 0 24 c0 20 29 49 143
    140 78 63 143 115 145 115 2 1 28 -19 58 -44z"/>
    </g>
    </svg>
);

export default Mail;
