import React from "react";

const Camsaoule = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 180.000000 180.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M1175 1649 c-11 -4 -16 -8 -11 -8 6 -1 1 -7 -9 -15 -11 -8 -23 -12
    -27 -10 -4 3 -5 -3 -1 -12 4 -11 2 -15 -4 -11 -17 10 -27 -21 -34 -100 -3 -31
    -5 -33 -40 -31 -25 1 -36 5 -32 14 4 12 -12 20 -58 29 -12 3 -25 8 -28 13 -3
    4 -21 14 -38 21 -18 7 -30 17 -28 22 8 11 -53 41 -67 33 -7 -5 -8 -3 -4 5 6 8
    2 11 -10 9 -11 -2 -18 2 -17 7 1 6 -16 11 -37 13 -29 2 -44 -3 -59 -20 -12
    -12 -21 -26 -21 -32 0 -5 -3 -6 -6 -3 -4 4 -18 -17 -31 -46 -13 -28 -30 -55
    -38 -59 -12 -7 -30 -54 -27 -70 1 -5 -5 -8 -13 -8 -8 0 -12 -6 -9 -14 3 -7 -2
    -16 -10 -19 -9 -4 -16 -16 -16 -27 0 -12 -4 -19 -9 -16 -5 4 -14 -6 -20 -21
    -10 -26 -11 -26 -7 -3 4 22 3 23 -8 8 -11 -15 -13 -15 -24 3 -10 15 -10 19 0
    19 18 1 -53 60 -72 60 -13 0 -13 1 0 10 11 8 8 9 -12 4 -18 -4 -28 -2 -28 5 0
    6 -7 11 -16 11 -8 0 -12 -4 -9 -10 4 -6 -7 -8 -27 -4 -25 4 -29 3 -18 -5 13
    -9 12 -11 -4 -11 -17 0 -96 -60 -79 -60 4 0 1 -5 -7 -10 -11 -7 -15 -28 -15
    -79 0 -57 3 -73 19 -84 15 -11 16 -16 6 -22 -9 -6 -11 -4 -5 6 4 8 -8 -4 -26
    -25 l-33 -40 -1 -149 c0 -82 3 -151 7 -153 4 -3 12 -23 18 -44 9 -34 19 -44
    59 -65 27 -13 52 -21 56 -17 5 4 5 -1 2 -10 -4 -13 -2 -15 10 -11 10 4 14 2
    10 -4 -8 -13 40 -36 55 -26 7 4 9 0 5 -9 -4 -12 -1 -14 14 -11 13 4 19 0 19
    -11 0 -10 5 -14 10 -11 6 3 10 2 10 -3 0 -6 7 -13 16 -16 8 -3 13 -2 10 3 -4
    5 4 7 16 3 12 -3 17 -7 10 -10 -7 -2 3 -14 23 -28 19 -13 42 -21 50 -18 8 4
    15 1 15 -5 0 -6 -4 -11 -10 -11 -5 0 -6 -8 -3 -18 5 -13 4 -15 -8 -7 -11 8
    -24 0 -55 -32 -23 -24 -40 -43 -39 -43 2 0 -1 -16 -6 -35 -15 -53 26 -137 80
    -165 40 -20 151 -29 151 -12 0 4 10 12 22 17 13 6 28 21 35 35 8 15 13 19 14
    10 2 -28 17 35 18 77 1 23 5 45 11 48 12 7 49 -11 74 -37 10 -11 24 -17 31
    -14 8 3 15 0 17 -5 2 -6 10 -11 18 -13 28 -6 50 -18 45 -26 -3 -4 9 -10 26
    -14 17 -3 28 -10 25 -15 -3 -5 4 -7 16 -4 16 3 19 2 10 -5 -10 -6 3 -13 45
    -25 81 -21 90 -19 112 27 11 23 20 35 21 28 0 -7 5 -10 10 -7 6 4 8 15 5 26
    -4 11 -3 19 2 18 20 -3 27 2 13 11 -10 6 -11 10 -2 10 6 0 12 6 12 14 0 7 7
    19 15 26 9 7 13 20 10 28 -3 9 0 12 9 9 11 -4 13 -1 9 10 -4 11 -2 14 5 9 8
    -5 10 -2 6 8 -3 9 -1 16 5 16 12 0 67 109 63 124 -2 4 2 14 8 21 8 9 8 15 0
    20 -6 3 -7 11 -4 17 4 7 2 8 -4 4 -7 -4 -12 -3 -12 2 0 11 -66 42 -91 42 -11
    0 -17 -4 -14 -10 3 -5 1 -10 -4 -10 -6 0 -11 4 -11 10 0 5 -7 7 -15 3 -9 -3
    -24 2 -35 12 -10 9 -24 14 -29 10 -6 -3 -11 0 -12 7 0 7 -2 21 -4 30 -2 14 4
    16 33 14 21 -2 44 2 52 9 20 17 64 30 56 17 -9 -14 49 -35 75 -27 14 5 19 4
    15 -4 -8 -12 -6 -13 46 -15 20 -1 34 2 31 7 -3 5 0 7 8 5 8 -3 31 1 52 8 22 8
    37 9 37 3 0 -6 8 -5 21 1 17 9 23 8 36 -7 8 -10 12 -13 9 -6 -8 15 10 27 19
    13 3 -5 13 -10 21 -10 9 0 12 5 8 11 -4 8 1 9 15 4 27 -8 66 16 74 46 7 25
    -45 84 -67 76 -14 -6 -15 -2 -20 60 -3 28 1 46 10 54 12 10 13 8 8 -7 -5 -15
    -3 -14 10 4 10 12 14 27 11 32 -3 6 -1 10 6 10 10 0 10 3 1 12 -9 9 -6 14 10
    22 11 6 25 21 29 34 13 34 -36 82 -83 82 -22 0 -45 9 -63 24 -15 13 -42 27
    -59 30 -22 5 -27 9 -17 15 18 12 -2 23 -26 14 -10 -3 -14 -2 -10 2 8 9 -56 45
    -80 45 -8 0 -11 5 -8 10 3 6 -4 10 -16 10 -12 0 -19 3 -16 6 4 4 -3 16 -15 26
    -19 16 -20 21 -8 28 8 6 11 10 5 10 -5 0 8 9 30 20 22 11 36 20 30 20 -5 0 -4
    4 4 9 8 5 15 35 18 78 5 63 3 71 -21 100 -15 18 -36 35 -47 38 -10 4 -17 11
    -14 16 7 10 2 11 -70 14 -27 1 -59 -1 -70 -6z m132 -36 c44 -21 73 -67 73
    -116 0 -61 -22 -91 -85 -117 -39 -16 -55 -28 -55 -41 0 -11 4 -19 9 -19 5 0
    16 -6 23 -13 7 -7 40 -28 73 -47 33 -18 103 -58 155 -89 90 -52 93 -56 65 -61
    -41 -7 -53 -38 -60 -149 -5 -90 -16 -131 -35 -131 -15 0 -17 69 -4 186 5 55 4
    65 -17 93 -12 17 -28 31 -33 31 -6 0 -4 -5 4 -10 11 -7 5 -10 -23 -10 -26 0
    -37 4 -35 12 5 14 -43 58 -64 58 -16 0 -58 -36 -58 -50 0 -6 -10 -10 -22 -11
    -22 0 -22 -1 -3 -9 18 -8 18 -9 2 -9 -10 -1 -15 -5 -12 -11 3 -6 -1 -7 -9 -4
    -9 3 -14 10 -11 14 3 4 -6 15 -20 24 -34 22 -42 20 -14 -5 l24 -20 -25 7 c-14
    4 -29 12 -33 18 -6 7 -7 4 -3 -6 3 -12 0 -18 -9 -18 -8 0 -15 -4 -15 -10 0
    -13 -52 -9 -67 6 -7 6 -10 14 -7 17 3 3 -4 8 -15 12 -20 6 -57 -10 -76 -33 -9
    -11 -21 -10 -65 7 -64 25 -63 24 -54 9 5 -8 -2 -9 -24 -5 -26 5 -30 4 -25 -10
    5 -13 3 -14 -15 -4 -11 6 -32 11 -46 11 -35 0 -57 10 -49 23 4 6 -10 1 -30
    -11 -20 -12 -37 -17 -37 -13 0 5 -13 11 -30 13 -22 3 -31 0 -36 -14 -3 -10
    -10 -18 -15 -18 -5 0 -9 -20 -9 -45 0 -38 -3 -44 -17 -39 -11 4 -14 3 -7 -3
    14 -10 32 -161 23 -194 -6 -23 -6 -23 -11 6 l-5 30 -2 -32 c-1 -22 7 -40 24
    -57 14 -14 26 -33 26 -43 1 -16 2 -16 6 1 4 15 7 16 14 5 8 -12 11 -11 20 4 5
    9 18 17 27 17 14 0 14 -2 3 -9 -10 -6 -1 -8 25 -6 22 2 42 -1 47 -8 6 -8 7 -6
    3 6 -7 21 3 49 15 41 5 -3 9 30 9 75 0 44 -4 83 -10 86 -5 3 -10 12 -10 18 0
    7 4 6 10 -3 8 -13 11 -11 16 8 4 12 14 22 23 22 11 0 12 -3 4 -8 -9 -6 -6 -15
    12 -34 30 -32 31 -40 8 -31 -14 5 -12 -1 7 -22 20 -22 21 -27 7 -22 -10 4 -16
    3 -13 -1 2 -4 -3 -23 -12 -41 -16 -33 -16 -34 13 -56 34 -28 77 -38 110 -25
    14 5 45 7 70 3 34 -4 45 -2 45 8 0 10 3 10 12 1 15 -15 58 -16 58 -2 0 6 -9
    10 -20 10 -17 0 -20 7 -20 45 0 38 -3 45 -20 45 -17 0 -20 -7 -20 -45 0 -41
    -2 -45 -25 -45 -23 0 -24 2 -18 45 4 33 1 57 -12 85 -17 37 -17 42 -1 83 9 23
    16 57 16 74 0 22 7 37 22 47 29 21 51 20 79 -3 26 -21 30 -71 9 -122 -10 -23
    -10 -39 -1 -72 7 -23 13 -60 15 -81 1 -22 8 -42 14 -44 7 -2 12 -19 12 -38 0
    -42 14 -71 49 -103 23 -21 32 -23 91 -17 51 4 71 2 88 -10 28 -20 28 -33 -4
    -94 -14 -27 -22 -55 -18 -61 4 -8 3 -9 -4 -5 -12 7 -28 -14 -70 -93 -63 -117
    -97 -171 -109 -171 -7 0 -13 3 -13 8 0 4 -15 14 -32 21 -32 14 -168 91 -272
    153 -26 16 -58 30 -70 31 -20 2 -21 -2 -21 -80 0 -80 -1 -82 -34 -112 -43 -38
    -104 -42 -161 -10 -100 56 -87 210 20 233 14 2 34 11 45 19 20 15 20 15 0 37
    -11 12 -29 26 -40 31 -11 5 -27 13 -35 19 -33 22 -172 100 -177 100 -3 0 -32
    18 -64 40 -32 22 -63 40 -69 40 -5 0 -10 7 -10 15 0 18 21 20 38 3 8 -8 12 -8
    12 -1 0 6 -16 23 -35 37 l-35 27 0 139 c0 147 4 160 51 175 32 10 73 -25 77
    -64 3 -29 1 -32 -20 -29 -15 2 -23 10 -23 22 0 24 -24 33 -36 15 -11 -18 -11
    -220 0 -238 12 -18 36 -9 36 15 0 12 8 20 23 22 17 3 22 -1 22 -20 0 -30 -26
    -68 -48 -69 -12 0 -11 -3 5 -9 29 -12 29 -28 1 -40 -22 -8 -22 -9 5 -9 18 -1
    27 4 27 15 0 9 5 12 12 8 8 -5 9 -1 4 12 -5 15 -4 16 5 7 6 -6 18 -9 27 -6 11
    5 13 3 7 -8 -5 -8 4 -3 18 10 15 14 27 19 27 13 0 -16 61 -15 82 2 13 9 18 26
    17 57 -1 38 -2 41 -10 19 -8 -22 -8 -21 -2 5 8 43 9 143 1 195 -4 25 -4 36 1
    26 6 -15 9 -16 13 -5 6 15 -16 42 -26 32 -3 -4 -6 -1 -6 5 0 25 -58 43 -123
    36 -58 -5 -64 -4 -95 23 -97 82 -15 259 110 238 52 -9 75 -31 96 -90 26 -73
    44 -67 104 30 27 44 61 103 75 130 14 28 29 52 33 55 4 3 10 16 14 30 3 14 20
    40 36 59 l29 34 58 -35 c32 -20 81 -48 108 -62 28 -15 73 -40 100 -56 28 -16
    56 -28 63 -25 7 3 10 1 7 -5 -7 -11 26 -30 54 -30 19 0 20 5 18 74 -2 67 0 77
    23 101 44 48 105 58 167 28z m21 -465 c23 -23 12 -35 -33 -36 -50 0 -53 3 -28
    30 19 21 44 23 61 6z m-916 -71 c1 -1 4 -75 7 -165 l6 -162 -27 0 c-27 0 -27
    1 -21 45 6 44 5 45 -22 45 -27 0 -27 -1 -23 -45 5 -42 4 -45 -18 -45 l-24 0 0
    153 c0 161 3 174 50 189 12 4 62 -7 72 -15z m162 -4 l14 -28 11 28 c7 17 18
    27 31 27 19 0 20 -6 20 -175 0 -175 0 -175 -22 -175 -23 0 -23 1 -21 127 2
    101 0 123 -9 106 -10 -21 -12 -22 -24 -5 -11 14 -13 0 -14 -93 0 -74 4 -115
    12 -123 9 -9 5 -12 -20 -12 l-32 0 0 175 c0 167 1 175 20 175 11 0 26 -12 34
    -27z m299 -1 c24 -21 23 -68 -2 -68 -12 0 -22 9 -26 23 -5 21 -34 33 -36 16 0
    -5 -3 -28 -5 -51 -5 -41 -3 -46 40 -85 40 -35 46 -46 46 -81 0 -23 -7 -47 -17
    -58 -20 -22 -70 -23 -95 -1 -22 19 -25 78 -4 70 7 -3 18 -1 23 4 7 7 10 1 9
    -18 -1 -22 3 -28 19 -28 13 0 21 8 23 24 3 18 -9 36 -47 72 l-52 49 7 53 c8
    64 19 86 47 96 25 9 44 5 70 -17z m290 17 c12 -7 25 -17 29 -23 11 -16 10 -78
    -2 -113 -8 -21 -7 -44 3 -83 17 -65 8 -102 -28 -118 -47 -21 -85 8 -85 64 0
    13 -7 42 -16 65 -16 38 -16 43 0 75 9 19 16 49 16 67 0 37 7 49 35 65 25 14
    22 14 48 1z m113 -61 c-12 -147 -3 -233 24 -233 11 0 14 10 12 40 -5 67 -3
    127 5 195 7 55 11 65 28 65 17 0 19 -6 16 -55 -1 -30 -7 -69 -13 -86 -8 -23
    -8 -40 1 -64 12 -37 14 -99 3 -116 -15 -23 -50 -34 -75 -25 -26 10 -37 29 -37
    64 0 12 -7 43 -17 69 -16 44 -16 49 2 87 12 28 16 54 13 86 -5 43 -4 45 19 45
    24 0 25 -1 19 -72z m160 0 c-8 -97 -8 -134 0 -191 l6 -48 42 3 c36 2 41 0 44
    -20 3 -21 0 -22 -65 -22 l-68 0 4 46 c2 29 -3 60 -14 85 -17 37 -17 41 -1 84
    10 24 17 62 16 82 -3 53 -2 53 21 53 20 0 20 -4 15 -72z m244 45 c0 -21 -4
    -23 -45 -23 -38 0 -45 -3 -45 -20 0 -15 7 -20 25 -20 21 0 23 -3 16 -22 -6
    -17 -16 -22 -35 -21 -18 1 -26 -3 -28 -15 -2 -31 3 -67 13 -77 5 -5 9 -26 9
    -48 l0 -38 45 7 c44 6 45 5 45 -20 0 -25 -2 -26 -65 -26 l-65 0 -1 38 c-1 44
    -3 60 -19 106 -9 28 -8 44 4 79 9 26 13 63 10 86 l-5 41 71 -2 c66 -1 70 -2
    70 -25z m-954 -33 c1 -46 -1 -50 -22 -50 -22 0 -24 4 -24 50 0 45 2 50 23 50
    20 0 22 -5 23 -50z m685 -306 c12 -7 -8 -34 -24 -34 -12 0 -47 30 -47 40 0 8
    56 3 71 -6z"/>
    <path d="M967 1044 c-4 -4 -7 -17 -7 -29 0 -13 -7 -38 -14 -57 -19 -44 -11
    -78 18 -78 18 0 21 7 24 43 1 23 3 60 5 81 2 37 -10 55 -26 40z"/>
    <path d="M337 1044 c-4 -4 -7 -43 -7 -86 0 -77 0 -78 26 -78 l25 0 -3 82 c-2
    65 -6 83 -18 86 -9 1 -19 0 -23 -4z"/>
    <path d="M1126 1038 c-28 -86 -19 -243 14 -243 11 0 14 10 12 40 -2 22 -3 60
    -3 85 0 25 1 63 3 84 3 38 -17 64 -26 34z"/>
    </g>
    </svg>
);

export default Camsaoule;
