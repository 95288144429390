import React from "react";

const ACE = ({ color, width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 300.000000 119.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,220.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M1563 2440 c-33 -17 -84 -54 -112 -83 -100 -100 -182 -316 -197 -519
    -7 -84 -15 -106 -28 -73 -12 32 -162 7 -181 -30 -5 -11 -63 -123 -129 -250
    -65 -126 -165 -317 -223 -423 -115 -211 -133 -265 -92 -280 42 -16 89 -3 110
    32 10 17 19 36 19 43 0 21 101 234 152 321 27 45 59 88 71 96 25 16 159 27
    179 15 10 -6 13 -42 13 -145 0 -158 4 -166 74 -146 37 12 42 17 56 64 8 28 15
    63 15 77 1 33 15 11 25 -39 4 -19 26 -76 50 -126 89 -185 205 -233 354 -147
    23 14 50 33 60 42 9 10 38 41 64 69 57 61 72 65 63 19 -4 -17 -9 -75 -12 -127
    -4 -73 -2 -99 9 -114 28 -38 67 6 67 76 l0 31 124 -7 c68 -4 167 -4 219 0 83
    6 97 9 103 26 9 30 -4 82 -27 107 -20 21 -26 22 -88 14 -60 -7 -281 -7 -289 1
    -1 2 -3 32 -3 67 -1 58 -4 88 -23 197 l-6 32 70 0 c97 0 116 22 85 97 -15 35
    -16 36 -60 30 -25 -4 -57 -4 -73 -1 -28 6 -29 6 -46 137 -9 73 -19 142 -22
    155 -6 22 -6 22 105 22 125 0 131 4 111 64 -13 40 -22 42 -187 45 l-103 2 0
    -64 c0 -35 4 -88 9 -118 5 -30 12 -94 16 -142 l6 -88 -25 7 c-22 5 -27 2 -36
    -22 -17 -45 -3 -84 36 -100 33 -14 34 -16 34 -74 0 -55 -2 -60 -22 -60 -13 0
    -44 -24 -82 -63 -72 -75 -142 -112 -207 -111 -108 1 -173 89 -220 299 -22 94
    -19 428 4 535 35 160 75 254 142 328 53 59 97 82 158 82 69 0 106 -31 140
    -117 16 -39 36 -74 44 -78 22 -8 60 30 74 73 29 87 -19 240 -97 312 -55 50
    -80 60 -153 60 -44 0 -72 -7 -118 -30z m204 -31 c90 -44 164 -223 123 -300
    -16 -29 -26 -23 -45 24 -60 151 -224 171 -350 42 -125 -130 -193 -391 -182
    -705 11 -305 84 -481 219 -526 89 -29 167 -1 282 105 40 36 82 73 94 81 12 8
    25 23 28 34 11 35 24 -6 28 -93 5 -86 12 -105 46 -136 20 -18 37 -20 188 -20
    l167 0 0 -25 c0 -23 -5 -26 -55 -33 -30 -4 -115 -4 -187 -1 -113 5 -137 4
    -158 -10 -13 -9 -26 -16 -28 -16 -2 0 -2 34 1 75 4 63 2 79 -13 95 -30 33 -53
    23 -124 -49 -190 -194 -327 -158 -432 115 -52 136 -87 147 -124 38 -15 -46
    -25 -59 -40 -59 -19 0 -20 7 -21 119 l-1 119 -32 29 -33 30 20 21 c36 40 44
    65 37 122 -17 133 -115 139 -176 12 -36 -76 -36 -97 -3 -136 l26 -31 -24 0
    c-34 0 -83 -34 -119 -82 -17 -24 -65 -113 -106 -198 -41 -85 -82 -171 -91
    -190 -20 -41 -52 -53 -52 -19 0 12 33 83 74 158 97 180 133 247 259 492 59
    113 115 213 124 221 24 21 93 31 126 19 23 -9 31 -7 47 9 22 22 24 28 40 155
    49 367 269 610 467 514z m328 -657 c5 -4 -28 -9 -75 -13 -47 -3 -93 -9 -102
    -13 -14 -6 -18 -4 -18 11 0 11 3 23 8 27 8 9 171 -2 187 -12z m-971 -211 c4
    -5 9 -34 12 -64 5 -47 3 -58 -15 -76 -23 -23 -89 -29 -108 -10 -12 12 12 89
    43 137 15 22 58 31 68 13z m827 -167 c21 -20 42 -28 86 -33 42 -4 58 -10 58
    -21 0 -11 -15 -16 -57 -20 -35 -3 -64 -12 -74 -22 -16 -16 -20 -15 -56 20 -21
    20 -38 41 -38 46 0 10 40 56 49 56 3 0 17 -12 32 -26z"/>
    </g>
    </svg>
);

export default ACE;